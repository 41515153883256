import {
    API__ChainReportViewModel,
    API__FFSocialLinks,
    API__FFYearValueData,
    API__KeyAttributesDataViewModel,
    API__ProfileViewModel,
    API__SectionDataViewModel,
} from "../apiSchema";
import { iPermissionFields, iPermissionSection, PermissionLevelEnum, PermissionSection } from "./PermissionSection";
import { keyAttributesConfig, stockInfoAttributesConfig } from "./ChainBase";
import {
    capitalize,
    getFormattedThousand,
    getFormattedThousandWithDecimal,
    getRoundedToSign,
    sortByField
} from "../helpers";
import { ReactNode } from "react";
import { MENU_TYPE_DEFAULT_FILTER_TITLE } from "./ChainsFilters";

const DEFAULT_KEY_ATTRIBUTES_DATA: API__KeyAttributesDataViewModel = {
    airport: false,
    mall: false,
    lodging: false,
    collegeHospital: false,
    hasBreakfast: false,
    hasLunch: false,
    hasDinner: false,
    maxSquareFootage: 0,
    minSquareFootage: 0,
    franchisedPercent: 0,
    primaryDistributor: 'No data',
    pos: 'No data',
    priceLevel: -1,
    priceLevelString: '',
    deliverySystem: 'No data',
    averageRating: 0,
    alcohol: -1,
    foundedYear: 0,
    holdingCompany: 'No data',
    holdingCompanyWebsite: 'No data',
};

export const NO_VALUE_PLACEHOLDER = 'N/A';

export enum ReportsNameEnum {
    Profile = "Profile",
    Firefly = "Operators Location Analysis",
    LTOActivity = "Launches & Ratings LTO Activity Report",
    BrandFingerprints = "Brand Performance Analysis",
    Menu = "Menu Report",
    Gaps = "BYO Gap Analysis",
    None = ""
}

export function getChainReportName(reportName: string) {
    switch (reportName) {
        case 'Profile':
            return ReportsNameEnum.Profile;
        case 'Firefly Location Analysis':
            return ReportsNameEnum.Firefly;
        case 'LTO Activity Report':
            return ReportsNameEnum.LTOActivity;
        case 'Brand Fingerprints Analysis':
            return ReportsNameEnum.BrandFingerprints;
        case 'Menu Report':
            return ReportsNameEnum.Menu;
        case 'BYO Gap Analysis':
            return ReportsNameEnum.Gaps;
        default:
            return ReportsNameEnum.None;
    }
}

export interface iKeyAttribute {
    title: string;
    value: string | ReactNode;
    testId?: string;
}

export interface iGroupYearAttribute {
    grow: string;
    values: iKeyAttribute[];
}

export class GroupYearAttribute implements iGroupKeyAttribute {
    grow = '';
    values: iKeyAttribute[] = [];

    constructor(data?: iGroupYearAttribute) {
        if (data) {
            this.setData(data);
        }
    }

    private setData(data: iGroupKeyAttribute) {
        ({
            grow: this.grow,
            values: this.values,
        } = data);
    }
}

export enum SocialLinkEnum {
    facebook = "facebook",
    foursquare = "foursquare",
    instagram = "instagram",
    linkedin = "linkedin",
    pinterest = "pinterest",
    snapchat = "snapchat",
    tumblr = "tumblr",
    twitter = "twitter",
    wikipedia = "wikipedia",
    youtube = "youtube",
    zomato = "zomato",
    none = "none"
}


export interface iSocialLink {
    title: SocialLinkEnum;
    url: string;
}

export class KeyAttributesData {
    sales: iGroupYearAttribute = new GroupYearAttribute();
    auv: iGroupYearAttribute = new GroupYearAttribute();
    units: iGroupYearAttribute = new GroupYearAttribute();
    stocks: iKeyAttribute[] = [];
    keyAttributes: iKeyAttribute[] = [];
}

export interface iMainData {
    id: number;
    logoUrl: string;
    segmentName: string;
    segmentId: number;
    cuisinePrimary: string;
    unitsCount: number;
    name: string;
    blurb: string;
    company: string;
    images: string[];
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    fax: string;
    email: string;
    website: string;
    socialLinks: API__FFSocialLinks;
    metroArea: string;
    stateFullName: string;
}

export interface iChainReport {
    name: ReportsNameEnum;
    price: number;
}

export interface iReportData extends iPermissionFields {
    data: iChainReport;
}

export interface iChainDetails {
    mainData: iMainData;
    keyAttributesData: iPermissionFields & {
        data: KeyAttributesData;
    };
    sectionsData: API__SectionDataViewModel;
    reportsData: iReportData[];
    notes: iPermissionSection<any[]>;
}

interface iData {
    apiModel: API__ProfileViewModel;
}

interface iGroupKeyAttribute {
    grow: string;
    values: iKeyAttribute[];
}

export class ChainDetails implements iChainDetails {
    mainData = ChainDetails.defaultData.mainData;
    keyAttributesData = ChainDetails.defaultData.keyAttributesData;
    sectionsData = ChainDetails.defaultData.sectionsData;
    reportsData = ChainDetails.defaultData.reportsData;
    notes = ChainDetails.defaultData.notes;

    static defaultData: iChainDetails = {
        mainData: {
            id: 0,
            name: '',
            cuisinePrimary: '',
            segmentName: '',
            company: '',
            logoUrl: '',
            blurb: '',
            address: '',
            city: '',
            phone: '',
            email: '',
            fax: '',
            website: '',
            socialLinks: {
                wikipedia: '',
                facebook: '',
                twitter: '',
                instagram: '',
                youtube: '',
                pinterest: '',
                zomato: '',
                foursquare: '',
                snapchat: '',
                tumblr: '',
                linkedin: '',
            },
            images: [],
            metroArea: '',
            state: '',
            unitsCount: 0,
            zip: '',
            segmentId: 0,
            stateFullName: '',
        },
        keyAttributesData: {
            ...new PermissionSection(),
            data: new KeyAttributesData(),
        },
        sectionsData: {
            hasBrand: false,
            hasContacts: false,
            hasLocation: false,
            hasLto: false,
            hasMenu: false,
            hasSocials: false,
        },
        reportsData: [],
        notes: {
            permissionLevel: PermissionLevelEnum.None,
            hasData: false,
        },
    };

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iChainDetails) {
        ({
            mainData: this.mainData,
            keyAttributesData: this.keyAttributesData,
            sectionsData: this.sectionsData,
            reportsData: this.reportsData,
            notes: this.notes,
        } = model);
    }

    static getGrowthString(value: number): string {
        if (!value && value !== 0) return NO_VALUE_PLACEHOLDER;
        return `${getRoundedToSign(value * 100)}%`;
    }

    static getChainReport = (data?: API__ChainReportViewModel) => {
        if (!data) {
            return { name: ReportsNameEnum.None, price: 0 }
        }

        return {
            price: data.price,
            name: getChainReportName(data.name)
        }
    }

    static mapUnitsItem(item: API__FFYearValueData): iKeyAttribute {
        return {
            title: `${item.year}`,
            value: item.value ? getFormattedThousand(item.value) : NO_VALUE_PLACEHOLDER,
        }
    }

    static mapSalesItem(item: API__FFYearValueData): iKeyAttribute {
        return {
            title: `${item.year}`,
            value: item.value ? `$${getFormattedThousandWithDecimal(item.value/(10**6), 1)}` : NO_VALUE_PLACEHOLDER,
        }
    }

    static mapAuvsItem(item: API__FFYearValueData): iKeyAttribute {
        return {
            title: `${item.year}`,
            value: item.value ? `$${getFormattedThousand(item.value)}` : NO_VALUE_PLACEHOLDER,
        }
    }

    private mapFromApi(data: API__ProfileViewModel) {
        const { companyData, reportsData, notes } = data;

        const reports = reportsData
            .map(i => ({
                permissionLevel: PermissionSection.getPermissionLevel(i.permissionLevel),
                hasData: i.hasData,
                data: ChainDetails.getChainReport(i.data),
            }))
            .filter(report => report.data.name);

        const newData: iChainDetails = {
            mainData: {
                ...companyData,
                cuisinePrimary: capitalize(companyData.cuisinePrimary || MENU_TYPE_DEFAULT_FILTER_TITLE),
            },
            keyAttributesData: ChainDetails.defaultData.keyAttributesData,
            sectionsData: data.sectionData,
            reportsData: reports,
            notes: {
                permissionLevel: PermissionSection.getPermissionLevel(notes.permissionLevel),
                hasData: notes.hasData,
            },
        };


        const units = companyData.unitsByYear;
        const sales = companyData.salesByYear;
        const auvs = companyData.auvByYear;

        units.sort(sortByField("year", "desc"));
        sales.sort(sortByField("year", "desc"));
        auvs.sort(sortByField("year", "desc"));

        const keyAttributesData = data.keyAttributesData.data || DEFAULT_KEY_ATTRIBUTES_DATA;
        const keyAttributesPermissionLevel = PermissionSection.getPermissionLevel(data.keyAttributesData.permissionLevel);

        newData.keyAttributesData = {
            permissionLevel: keyAttributesPermissionLevel,
            hasData: data.keyAttributesData.hasData,
            data: {
                keyAttributes: data.keyAttributesData.hasData
                    ? keyAttributesConfig
                        .filter(i => i.getIsShown(keyAttributesData))
                        .map(i => ({
                            title: i.label,
                            value: i.getValue(keyAttributesData),
                            testId: i.getTestId ? i.getTestId(keyAttributesData) : undefined,
                        }))
                    : keyAttributesConfig.map(i => ({
                        title: i.label,
                        value: 'Content protected',
                    })),
                stocks: stockInfoAttributesConfig
                    .filter(i => i.getIsShown(data.companyData))
                    .map(i => ({
                        title: i.label,
                        value: i.getValue(data.companyData),
                    })),
                units: {
                    grow: ChainDetails.getGrowthString(companyData.unitsGrowth),
                    values: units.slice(0, 3).map(ChainDetails.mapUnitsItem)
                },
                sales: {
                    grow: ChainDetails.getGrowthString(companyData.salesGrowth),
                    values: sales.slice(0, 3).map(ChainDetails.mapSalesItem)
                },
                auv: {
                    grow: ChainDetails.getGrowthString(companyData.auvGrowth),
                    values: auvs.slice(0, 3).map(ChainDetails.mapAuvsItem)
                },
            },
        };

        this.setData(newData);
    }
}

export interface iProfileData {
    profileData: iPermissionFields & {
        data: ChainDetails;
    };
    isInTop1000: boolean;
}

export class ProfileData implements iProfileData {
    static defaultData: iProfileData = {
        profileData: {
            ...new PermissionSection(),
            data: new ChainDetails(),
        },
        isInTop1000: true,
    };

    profileData = ProfileData.defaultData.profileData;
    isInTop1000 = ProfileData.defaultData.isInTop1000;
}