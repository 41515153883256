import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IconFireflyChains } from "../../icons";
import Button from "../../components/new/Button/Button";
import NewTopChains from "./NewTopChains";
import useFireflyChains from "../../core/apiHooks/useFireflyChains";
import Loader from "../../components/new/Loader/Loader";
import { getFormattedThousand } from "../../core/helpers";
import PageContent from "../../containers/PageContent/PageContent";
import Page from "../../containers/Page/Page";
import NotesPopup from "./NotesPopup/NotesPopup";
import { AppContext } from "../../AppContext";
import { ChainsFilters, iChainsFilters } from "../../core/models/ChainsFilters";
import { useChainsExport } from "../../core/apiHooks/useChainsExport";
import NotificationPopup from "../../components/NotificationPopup/NotificationPopup";
import analytics from "../../core/analytics";
import UsChainsHeader from "@pages/_shared/UsChainsHeader/UsChainsHeader";

const FireflyChains: FC = () => {
    const {
        globalVariables: {
            hasFFChainsFeaturePermission: hasPermission,
        },
    } = useContext(AppContext);
    const [count, setCount] = useState(0);
    const { isLoading, data } = useFireflyChains();
    const [isNotesShown, setIsNotesShown] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<iChainsFilters>(new ChainsFilters());
    const [selectedChainsIds, setSelectedChainsIds] = useState<number[]>([]);

    const {
        requestExportChains,
        isDownloading,
        isNotificationShow
    } = useChainsExport(selectedChainsIds, data.filtersData, selectedFilters);

    const handleFiltersChange = useCallback((selectedFilters: iChainsFilters, selectedChainsIds: number[]) => {
        setSelectedFilters(selectedFilters);
        setSelectedChainsIds(selectedChainsIds);
    }, []);

    useEffect(() => {
        analytics.sendFireflyChainsPageView();
    }, []);

    const handleClosePopup = useCallback(() => setIsNotesShown(false), []);

    return (
        /* @ts-ignore */
        <Page>
            <PageContent>
                <UsChainsHeader data={data} isLoading={isLoading}/>
                <div className="NewTopChains">
                    {isLoading && <Loader/>}
                    {!isLoading && (
                        <div className="NewTopChains__wrapper">
                            <NotificationPopup
                                isOpened={isNotificationShow}
                                hasCloseButton={false}
                                style={NotificationPopupStyles}
                                className="notification-popup--page"
                            >
                                {NotificationPopupContent}
                            </NotificationPopup>
                            <div className="NewTopChains__header">
                                <div className="NewTopChains__part">
                                    {getFormattedThousand(count)} matching chains
                                </div>
                                <div className="NewTopChains__part">
                                    {hasPermission && (
                                        <Button
                                            className="NewTopChains__button"
                                            modifiers={["primary", "accent"]}
                                            onClick={requestExportChains}
                                            isLoading={isDownloading}
                                        >
                                            XLS Export
                                        </Button>
                                    )}
                                    {hasPermission && (
                                        <Button
                                            className="NewTopChains__button"
                                            modifiers={["primary", "accent-gradient"]}
                                            onClick={() => setIsNotesShown(true)}
                                        >
                                            My Notes
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <NewTopChains
                                data={data}
                                setCount={setCount}
                                hasFilters={hasPermission}
                                handleFiltersChange={handleFiltersChange}
                            />

                            {hasPermission && (
                                <NotesPopup
                                    isShown={isNotesShown}
                                    onClose={handleClosePopup}
                                />
                            )}
                        </div>
                    )}
                </div>
            </PageContent>
        </Page>
    );
};
const NotificationPopupContent = <span>Report is ready to save...</span>;
const NotificationPopupStyles = {
    width: "auto",
    height: "auto"
};

export default FireflyChains;