import { FC } from "react";
import cn from "classnames";
import './TabThumb.styles.scss';

interface iProps {
    isActive?: boolean;
    className?: string;
    onClick?: () => void;
}

const TabThumb: FC<iProps> = (
    {
        isActive = false,
        className = '',
        onClick = () => null,
        children,
    }
) => {
    const classNames = cn(
        className,
        'TabThumb',
        { "is-active": isActive }
    );

    return (
        <div
            className={classNames}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default TabThumb;