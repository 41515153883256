import Http from './fetch';

class Analytics {
    constructor() {
    }

    send(category, action, label) {
        if ( window.ga ) {
            window.ga('send', {
                hitType: 'event',
                eventCategory: category,
                eventAction: action,
                eventLabel: label,
                eventValue: 1
            });
        }
        const properties = {
            Category: category,
            Action: action,
            Label: label,
        };
        const userId = window.pageData.userId || 'default';
        const companyName = window.pageData.companyName || 'default';
        const email = window.pageData.currentUserEmail;
        const escaped = '"' + JSON
            .stringify({...properties, userId, companyName, email })
            .replace(new RegExp('"', 'g'), '\\"')
            .replace(new RegExp('/', 'g'), '\\/') + '"';

        if (process.env.REACT_APP_ANALYTICS_API !== '') {
            Http.post('analytics api', {}, {
                body:  escaped,
                headers: {
                    'content-type': 'application/json',
                },
            }, 'bool');
        }
    }

    sendClick(action, label) {
        this.send('Click', action, label);
    }

    sendDownload(action, label) {
        this.send('Download', action, label);
    }

    sendSearch(searchQuery) {
        this.send('Search', 'Sitewide search', searchQuery);
    }

    sendPageView(action, label) {
        this.send('Page view', action, label);
    }

    sendLogout() {
        this.sendClick('Logout', `From: ${document.location.pathname}`);
    }

    sendAutosuggest(label) {
        this.sendClick('Autosuggest', label);
    }

    sendMainToolbar(productName) {
        this.sendClick('Main Toolbar', productName);
    }

    sendAdditionalToolbar(productName) {
        this.sendClick('Additional Toolbar', productName);
    }

    sendTeamsRedirect(title) {
        this.sendClick('Teams', title);
    }

    sendPackagesRedirect() {
        this.sendClick('Packages', 'packages');
    }

    sendSubMainToolbar(pageName) {
        this.sendClick('Sub Main Toolbar', pageName);
    }

    sendThreeTabs(tabName) {
        this.sendClick('Three tabs', tabName);
    }

    sendDownloadFoodProfileExport(word) {
        this.sendDownload('Food Profile Export', word);
    }
    sendDownloadMAC(label) {
        this.sendDownload('MAC', label);
    }
    sendDownloadReports(title) {
        this.sendDownload('Reports', title);
    }
    sendDownloadTrendspotting(title) {
        this.sendDownload('Trendspotting', title);
    }
    sendDownloadExportScoresPpt(title) {
        this.sendDownload('Export Scores PPT', title);
    }
    sendDownloadFoodProfiles(title) {
        this.sendDownload('Food Profiles', title);
    }

    sendSearchMoreInCategory(title) {
        this.sendAutosuggest(title);
    }
    sendOpenResultByPlaceInSearch(title) {
        this.sendAutosuggest(title);
    }

    sendDocumentsTabsNavigation(title) {
        this.sendClick('Documents action', title);
    }
    sendDocumentsTabItemBtn(btnType) {
        this.sendClick('Documents action', btnType);
    }

    sendRecommendedCharts(title) {
        this.sendClick('Recommended charts', title);
    }

    sendChainDataTab(title) {
        this.sendClick('Chain page', title);
    }

    sendDownloadReportsMenu(title) {
        this.sendClick('Download reports', title);
    }

    sendChainViewDetails(title) {
        this.sendClick('Firefly chains', title);
    }

    sendApplyFilter(title) {
        this.sendClick('Firefly chains', title);
    }

    sendPlacesExportFileType(title) {
        this.sendDownload('Places', title);
    }

    sendTopChainsExportFileType(title) {
        this.sendDownload('Firefly chains', title);
    }
    sendDocumentView(docType, title) {
        this.send('Page view', docType, title);
    }

    sendChainPageView(chainName) {
        this.sendPageView('Chain page', chainName);
    }

    sendFireflyChainsPageView() {
        this.sendPageView('Firefly Chains page', '');
    }

    sendPlacesPageView(searchQuery) {
        this.sendPageView('Places page', searchQuery);
    }

    sendChainPageDownload(label) {
        this.sendDownload('Chain page', label);
    }

    sendFoodView(keywordName) {
        this.sendPageView('Food Profile', keywordName);
    }

    sendInstantChartView(instantChartTitle) {
        this.sendPageView('Instant Chart', instantChartTitle);
    }
}

export default new Analytics();
