import { iChainsFilters } from "../models/ChainsFilters";
import { API__ChainFilterData, API__ChainsFilter } from "../apiSchema";
import { getAlcoholString } from "../models/ChainBase";
import Http from '../fetch';
import { useMemo, useState } from "react";
import useNotification from "../hooks/useNotification";
import analytics from "../analytics";

export function getFiltersForExport (
    allFilters: iChainsFilters,
    selectedFilters: iChainsFilters,
    searchQuery: string = '',
): API__ChainFilterData {
    const {
        minUnits,
        maxUnits,
        states: selectedStates,
        menuTypes: selectedMenuTypes,
        metros: selectedMetros,
        segments: selectedSegments,
        alcohol: selectedAlcohol,
    } = selectedFilters;

    const {
        states: allStates,
        menuTypes: allMenuTypes,
        metros: allMetros,
        segments: allSegments,
        alcohol: allAlcohol,
    } = allFilters;

    return {
        searchQuery,
        unitsTo: maxUnits,
        unitsFrom: minUnits,
        states: selectedStates.length === allStates.length ? [] : selectedStates,
        segments: selectedSegments.length === allSegments.length ? [] : selectedSegments,
        metros: selectedMetros.length === allMetros.length ? [] : selectedMetros,
        menuTypes: selectedMenuTypes.length === allMenuTypes.length ? [] : selectedMenuTypes,
        alcohols: selectedAlcohol.length === allAlcohol.length ? [] : selectedAlcohol.map(getAlcoholString),
    };
}

async function getChainsExport(chainsIds: number[], filters: API__ChainFilterData): Promise<any> {
    const reqData: API__ChainsFilter = {
        ids: chainsIds,
        filter: filters,
    };
    return await Http.downloadFileXHR(
        'POST',
        'export chains list',
        {},
        JSON.stringify(reqData)
    );
}

export function useChainsExport(
    chainsIds: number[],
    allFilters: iChainsFilters,
    selectedFilters: iChainsFilters,
    searchQuery: string = '',
) {
    const [isDownloading, setIsDownloading] = useState(false);
    const notification = useNotification();
    const filtersForExport = useMemo(
        () => getFiltersForExport(allFilters, selectedFilters, searchQuery),
        [allFilters, selectedFilters, searchQuery]
    );

    const requestExportChains = async (type: 'Firefly Chains' | 'Places') => {
        setIsDownloading(true);
        await getChainsExport(chainsIds, filtersForExport);
        setIsDownloading(false);
        notification.handleShowNotification();
        if (type === "Firefly Chains") {
            analytics.sendTopChainsExportFileType('Excel | chain export');
        } else {
            analytics.sendPlacesExportFileType('Excel | chain export');
        }
    };

    return {
        requestExportChains,
        isDownloading,
        isNotificationShow: notification.isOpen
    };
}