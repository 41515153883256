import { Alcohol, getAlcohol, iChainBase } from "./ChainBase";
import { DEFAULT_FILTER_TITLE, MENU_TYPE_DEFAULT_FILTER_TITLE } from "./ChainsFilters";
import { API__ChainBaseViewModel } from "../apiSchema";
import { capitalize } from "../helpers";
import { getState } from "../enums";

export class Place implements iChainBase {
    static defaultData: iChainBase = {
        id: 0,
        name: '',
        company: '',
        metroArea: '',
        stateFullName: '',
        numberOfUnits: 0,
        unitsGrowth: 0,
        segment: '',
        menuType: '',
        alcohol: Alcohol.NoData
    };

    id = Place.defaultData.id;
    name = Place.defaultData.name;
    company = Place.defaultData.company;
    metroArea = Place.defaultData.metroArea;
    stateFullName = Place.defaultData.stateFullName;
    numberOfUnits = Place.defaultData.numberOfUnits;
    unitsGrowth = Place.defaultData.unitsGrowth;
    segment = Place.defaultData.segment;
    menuType = Place.defaultData.menuType;
    alcohol = Place.defaultData.alcohol;

    constructor(data: { apiModel?: API__ChainBaseViewModel }) {
        if (data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    setData(model: iChainBase) {
        ({
            id: this.id,
            name: this.name,
            company: this.company,
            metroArea: this.metroArea,
            stateFullName: this.stateFullName,
            numberOfUnits: this.numberOfUnits,
            unitsGrowth: this.unitsGrowth,
            segment: this.segment,
            menuType: this.menuType,
            alcohol: this.alcohol
        } = model);
    }

    private mapFromApi(apiModel: API__ChainBaseViewModel) {
        this.setData({
            id: apiModel.id,
            name: apiModel.chainName,
            company: apiModel.parentCompanyName ?? DEFAULT_FILTER_TITLE,
            metroArea: apiModel.metroArea ?? DEFAULT_FILTER_TITLE,
            stateFullName: getState(apiModel.stateFullName) || DEFAULT_FILTER_TITLE,
            numberOfUnits: apiModel.numberOfUnits,
            unitsGrowth: apiModel.unitsGrowth,
            segment: apiModel.segment ?? DEFAULT_FILTER_TITLE,
            menuType: capitalize(`${apiModel.menuType ?? MENU_TYPE_DEFAULT_FILTER_TITLE}`),
            alcohol: getAlcohol(apiModel.alcohol)
        });
    }
}