import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ellipsis } from 'react-multiline-ellipsis';
import analytics from '../../core/analytics';
import { capitalize } from '../../core/old_helpers';

class Title extends Component {
    render() {
        const { text } = this.props;
        const classList = new Set(['search-popup__result-title']);
        const textModified = text.split(' ').map(word => {
            if (word.toLowerCase() === 'cpp') {
                return "CPP";
            } else {
                return capitalize(word);
            }
        }).join(' ');
        let smallText = '';

        // decrease font size of penetration
        let defaultText = textModified;
        const penetrationRegExp = /\(\d.?\.\d.?% Penetration\)/g;
        const match = textModified.match(penetrationRegExp);
        if (match && match.length > 0) {
            defaultText = textModified.substring(0, textModified.indexOf(match));
            smallText = match[0].toLowerCase();
        }
        // const smallText = textModified.replace(/%20\(\d.?\.\d.?%25%20penetration\)/g, '')
        return (
            <div className={Array.from(classList).join(' ')}>
                <span className="search-popup__result-title-inner">
                    {defaultText}
                    {smallText.length > 0 && (
                        <span className="search-popup__result-title-inner--small">
                            {smallText}
                        </span>
                    )}
                </span>
            </div>
        );
    }
}

Title.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string
};

class SearchCategoryItem extends Component {

    renderTitle() {
        const { title, url, addLink } = this.props;
        const WrapTitle = ellipsis(Title, 2);
        return (
            <WrapTitle
                text={title}
                title={title}
                url={url}
                addLink={addLink}
            />
        );
    }

    renderSubTitle() {
        if (this.props.subTitle) {
            return (
                <div
                    className="search-popup__result-subtitle"
                    dangerouslySetInnerHTML={{
                        __html: this.props.subTitle
                    }}
                />
            );
        }

        return null;
    }

    handleClick() {
        const { categoryTitle, query, index, title } = this.props;
        let capitalizeTitle = title.replace(/\w\S*/g,
            function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        analytics.sendOpenResultByPlaceInSearch(`${categoryTitle} | ${query} | ${index + 1} | ${capitalizeTitle}`);
    }

    render() {
        const { className = '', url = {}, href = '', testId } = this.props;
        const classList = new Set(['search-popup__category-result', ...className.split(' ')]);

        if (href) {
            return (
                <a
                    target="_blank"
                    href={href}
                    className={Array.from(classList).join(' ')}
                    onClick={this.handleClick.bind(this)}
                    data-testid={testId}
                >
                    {this.renderTitle()}
                    {this.renderSubTitle()}
                </a>
            );
        }

        return (
            <Link
                to={url}
                className={Array.from(classList).join(' ')}
                onClick={this.handleClick.bind(this)}
                data-testid={testId}
            >
                {this.renderTitle()}
                {this.renderSubTitle()}
            </Link>
        );
    }
}

SearchCategoryItem.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    url: PropTypes.object,
    addLink: PropTypes.bool,
    id: PropTypes.number,
    section: PropTypes.string,
    docType: PropTypes.number,
    query: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    categoryTitle: PropTypes.string,
    index: PropTypes.number
};

export default SearchCategoryItem;
