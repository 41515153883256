import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getRouteUrlByDocType } from '../../core/old_helpers';
import './ColumnsList.scss';

function ColumnsListItem(params) {
    const { title, subTitle } = params;
    const routeParams = getRouteUrlByDocType(params);
        return (
            <Link to={routeParams} className="search-popup-columns-list__item search-popup__category-result">
                <div className="search-popup__result-title">
                    {title.toLowerCase()}
                </div>
                <div className="search-popup__result-subtitle">
                    {subTitle}
                </div>
            </Link>
        );
}

ColumnsListItem.propTypes = {
    title: PropTypes.string
};

export default function ColumnsList({ items, query }) {
    return (
        <div className="search-popup-columns-list search-popup-category--expanded__items">
            {items.map((item, index) => <ColumnsListItem key={index} query={query} {...item}/>)}
        </div>
    );
}

ColumnsList.propTypes = {
    items: PropTypes.array
};
