import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import Table from "../../components/new/Table/Table";
import Filters from "./Filters/Filters";
import { ChainsFilters, iChainsFilters } from "../../core/models/ChainsFilters";
import { iChainBase } from "../../core/models/ChainBase";
import { useTableConfig } from "./useTableConfig";
import { iTopChainsData } from "../../core/models/TopChainsData";
import useTableSort from "../../components/new/Table/useTableSort";
import './NewTopChains.styles.scss';
import { NO_VALUE_PLACEHOLDER } from "../../core/models/ChainDetails";
import { CopyButtonContext } from "../../components/new/Table/CopyButton";
import { hasMatches, prepareForMatching } from "@helpers";


const EXPORT_MAX_LENGTH = 50;

function getDefaultColumnSorting(fieldName: keyof iChainBase | null): 'asc' | 'desc' {
    if (!fieldName) return 'asc';
    const descColumns: Array<keyof iChainBase> = ["numberOfUnits", "unitsGrowth"];
    if (descColumns.includes(fieldName)) return 'desc';
    return 'asc';
}

interface iProps {
    data: iTopChainsData;
    setCount: (count: number) => void;
    hasFilters?: boolean;
    handleFiltersChange: (selectedFilters: iChainsFilters, chainsIds: number[]) => void;
}

export type LocationsTab = "metros" | "states";

const NewTopChains: FC<iProps> = (
    {
        data,
        setCount,
        hasFilters = false,
        handleFiltersChange,
    }
) => {
    const { places: rawPlaces, filtersData } = data;
    const [selectedFilters, setSelectedFilters] = useState<iChainsFilters>(new ChainsFilters());
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedLocationsTab, setSelectedLocationsTab] = useState<LocationsTab>("states");

    const filteredPlaces = useMemo(
        () => rawPlaces.filter(place => {
            const {
                segments: selectedSegments,
                menuTypes: selectedMenuTypes,
                metros: selectedMetros,
                states: selectedStates,
                minUnits: selectedMinUnits,
                maxUnits: selectedMaxUnits,
                alcohol: selectedAlcohol
            } = selectedFilters;
            const {
                segments: allSegments,
                menuTypes: allMenuTypes,
                metros: allMetros,
                states: allStates,
                alcohol: allAlcohol
            } = filtersData;

            let isMatched = true;
            if (selectedSegments.length && selectedSegments.length !== allSegments.length)
                isMatched = isMatched && selectedSegments.includes(place.segment);
            if (selectedStates.length && selectedStates.length !== allStates.length && selectedLocationsTab === "states")
                isMatched = isMatched && selectedStates.includes(place.stateFullName);
            if (selectedMetros.length && selectedMetros.length !== allMetros.length && selectedLocationsTab === "metros")
                isMatched = isMatched && selectedMetros.includes(place.metroArea);
            if (selectedMenuTypes.length && selectedMenuTypes.length !== allMenuTypes.length)
                isMatched = isMatched && selectedMenuTypes.includes(place.menuType);
            if (selectedAlcohol.length && selectedAlcohol.length !== allAlcohol.length)
                isMatched = isMatched && selectedAlcohol.includes(place.alcohol);
            if (!!searchQuery.trim()) {
                isMatched = isMatched && hasMatches(place.name, searchQuery);
            }
            isMatched = isMatched && place.numberOfUnits >= selectedMinUnits;
            isMatched = isMatched && place.numberOfUnits <= selectedMaxUnits;
            return isMatched;
        }),
        [searchQuery, rawPlaces, selectedFilters, filtersData, selectedLocationsTab]
    );

    const {
        handleSort,
        sortingField,
        sortingDirection,
        getColumnIcon,
        setSortingDirection,
        sortedData: places
    } = useTableSort(filteredPlaces, "numberOfUnits");

    const tableConfig = useTableConfig(
        places,
        sortingField,
        sortingDirection,
        handleSort,
        getColumnIcon,
        searchQuery,
    );

    useEffect(() => {
        handleFiltersChange(
            {
                ...selectedFilters,
                states: selectedLocationsTab === "states" ? selectedFilters.states : [],
                metros: selectedLocationsTab === "metros" ? selectedFilters.metros : [],
            },
            places.map(i => i.id)
        );
    }, [selectedFilters, selectedLocationsTab, places]);

    useEffect(() => {
        setSelectedFilters(new ChainsFilters({ subModel: {
            maxUnits: filtersData.maxUnits,
        } }));
    }, [filtersData]);

    useEffect(() => {
        setSortingDirection(getDefaultColumnSorting(sortingField));
    }, [sortingField, setSortingDirection]);

    useEffect(() => {
        setCount(places.length);
    }, [places]);


    const getClipboardData = useMemo(() => ({ head, data }: { head: string[], data: iChainBase[] }) => {
        return [
            head,
            ...data.map(item => {
                return [
                    item.name,
                    item.metroArea,
                    item.stateFullName,
                    `${item.numberOfUnits || NO_VALUE_PLACEHOLDER}`,
                    `${item.unitsGrowth || NO_VALUE_PLACEHOLDER}`,
                    item.segment,
                    item.menuType
                ]
            })
        ]
    }, []);


    return (
        <>
            {hasFilters && (
                <div className="NewTopChains__filters">
                    <Filters
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        data={filtersData}
                        selectedFilters={selectedFilters}
                        applyFilters={setSelectedFilters}
                        selectedLocationsTab={selectedLocationsTab}
                        setSelectedLocationsTab={setSelectedLocationsTab}
                    />
                </div>
            )}
            <div className="NewTopChains__table">
                <CopyButtonContext.Provider value={{
                    data: getClipboardData({
                        head: tableConfig.map(item => item.title || ""),
                        data: places.slice(0, EXPORT_MAX_LENGTH)
                    }),
                }}>
                    <Table
                        config={tableConfig}
                        rowsCount={places.length}
                    />
                </CopyButtonContext.Provider>
            </div>
        </>

    );
};

export default memo(NewTopChains);