import React, { CSSProperties, FC, useCallback, useContext, useMemo, useRef, useState } from "react";
import cn from "classnames";
import TruncatedText from "react-dotdotdot";
import Http from "../../../core/fetch";
import { iLTO } from "../../../core/models/LTO";
import useNotification from "../../../core/hooks/useNotification";
import { SectionEnum } from "../../../core/enums";
import useWindowSizeDebounced from "../../../core/hooks/useWindowSizeDebounced";
import { ChainDetailsDataContext } from "@pages/ChainPage/ChainPage.helpers";
import { getFormattedDate } from "../../../core/helpers";
import analytics from "../../../core/analytics";
import { IconDownloadSimple, IconScoresNew } from "../../../icons";
import { isAvailable } from "../../../core/models/PermissionSection";
import Scores from "@pages/ChainPage/Sections/LTO/LTOItem/Scores/Scores";
import Button from "../../../components/new/Button/Button";
import NotificationPopup from "../../../components/NotificationPopup/NotificationPopup";
import "./LTOItem.styles.scss";
import {
    AnalyticsAction,
    AnalyticsAttribute,
    FoodProfileDownloadLocation, getAnalyticsComputedData
} from "@models/AnalyticsAttributes";
import http from "@core/fetch";
import { useDispatch } from "react-redux";
import { addNotification } from "@components/GlobalNotification/globalNotification.actions";
import SimpleLoader from '@components/SimpleLoader/SimpleLoader';

interface iProps {
    data: iLTO;
    style?: {
        root?: CSSProperties,
        wrapper?: CSSProperties,
    };
    location: 'chain page' | 'food profile';
    testId?: string;
}

const LTOItem: FC<iProps> = (
    {
        data,
        style,
        location,
        testId,
    }
) => {
    const dispatch = useDispatch();
    const showNotification = (notification: any) => dispatch(addNotification(notification));
    const { handleShowNotification, isOpen } = useNotification();
    const [isRequestSent, setIsRequestSend] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const leadSource = useMemo(() => {
        switch (location) {
            case 'chain page': return SectionEnum.LtoTileChainPage;
            case 'food profile': return SectionEnum.LtoTileFoodProfile;
            default: return '';
        }
    }, [location]);

    const handleUnlockRequest = () => {
        Http.get('unlock request', { leadSource, ltoName: name }, {}, "none");
        handleShowNotification();
        setIsRequestSend(true);
    }

    const {
        name,
        chainName,
        date,
        description,
        imageUrl,
        isScoresItem,
        price,
        scoresData,
    } = data;

    const {
        data: {
            downloadPptLink,
            indexes,
            score,
            viability,
            link,
        }
    } = scoresData;

    const itemRef = useRef<HTMLDivElement>(null);
    const [wWidth] = useWindowSizeDebounced(1000);
    const {
        data: {
            mainData: {
                logoUrl
            }
        }
    } = useContext(ChainDetailsDataContext);

    const isHiddenScores = !isScoresItem;
    const descriptionLines = isHiddenScores ? 9 : 3;

    const classnames = cn(
        "LTOItem",
        !!link ? "is-clickable" : ""
    );

    const downloadFile = useCallback(() => {
        setIsDownloading(true);
        showNotification({ text: 'Downloading…', });
        http.downloadFileXHRFromUrl('GET', downloadPptLink)
            .then(() => {
                showNotification({ text: 'Download Successful'});
                setIsDownloading(false);
            });
    }, [data]);

    return (
        <div
            className={classnames}
            style={style?.root}
            ref={itemRef}
            data-testid={testId}
        >

            <div className="LTOItem__wrapper" style={style?.wrapper}>
                {isScoresItem && !!link && (
                    <a
                        className="LTOItem__link"
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        {...getAnalyticsComputedData({
                            [AnalyticsAttribute.ClickTitle]: 'Scores Redirect',
                        })}
                    >
                        {""}
                    </a>
                )}
                {isScoresItem && !!downloadPptLink && (
                    <div
                        className={cn(
                            'LTOItem__download',
                            'clickable',
                            isDownloading && 'LTOItem__download--loading',
                        )}
                        {...getAnalyticsComputedData({
                            [AnalyticsAttribute.Action]: AnalyticsAction.Download,
                            [AnalyticsAttribute.DownloadLocation]: FoodProfileDownloadLocation.FoodProfileLto,
                            [AnalyticsAttribute.ClickTitle]: 'Chains Featuring Keyword - Download',
                        })}
                        onClick={e => {
                            e.stopPropagation();
                            if (!isDownloading) {
                                analytics.sendDownloadExportScoresPpt(`${name} | PPT`);
                                downloadFile();
                            }
                        }}
                        data-testid="buttonLtoDownload"
                    >
                        {isDownloading ? <SimpleLoader/> : <IconDownloadSimple/>}
                        Download
                    </div>
                )}
                <div className="LTOItem__header">
                    <div
                        className="LTOItem__sub-title"
                        title={chainName}
                        data-testid="textLtoChainName"
                    >
                        {chainName}
                    </div>
                    <div
                        className="LTOItem__title"
                        title={name}
                        data-testid="textLtoTitle"
                    >
                        {/* @ts-ignore */}
                        <TruncatedText clamp={2}>{name}</TruncatedText>
                    </div>
                </div>

                <div className={`LTOItem__content ${isHiddenScores ? "is-blank" : ""}`}>
                    <div className="LTOItem__image-wrapper">
                        <img
                            src={imageUrl || logoUrl}
                            className={`LTOItem__image ${imageUrl ? "is-cover" : ""}`}
                            alt=""
                            data-testid="imageLtoThumbnail"
                        />
                    </div>
                    <div className="LTOItem__description-wrapper">
                        <div
                            className="LTOItem__description"
                            data-testid="textLtoDescriptionExpanded"
                        >
                            {/* @ts-ignore */}
                            <TruncatedText
                                clamp={descriptionLines}
                                key={wWidth}
                            >
                                {description}
                            </TruncatedText>
                        </div>
                        <div
                            className="LTOItem__description LTOItem__description-hover"
                            data-testid="textLtoDescriptionExpanded"
                        >
                            {description}
                        </div>
                    </div>
                </div>
                <div
                    className={`LTOItem__scores ${isHiddenScores ? "is-blank" : ""}`}
                >
                    {isScoresItem && (
                        <>
                            {isAvailable(scoresData) ? (
                                <Scores data={indexes} score={score} title={viability}/>
                            ) : (
                                <div className="LTOItem__scores-attention">
                                    <div className="LTOItem__scores-attention-icon">
                                        <IconScoresNew width={32} height={32}/>
                                    </div>
                                    <div className="LTOItem__scores-attention-title">
                                        Unlock consumer ratings<br/>with an upgraded subscription
                                    </div>
                                    <Button
                                        modifiers={["primary"]}
                                        isDisabled={isRequestSent}
                                        className="LTOItem__scores-attention-button"
                                        onClick={handleUnlockRequest}
                                    >
                                        Contact us
                                    </Button>
                                    <NotificationPopup
                                        isOpened={isOpen}
                                        hasCloseButton={false}
                                        style={{ bottom: 10 }}
                                        className="notification-popup--table LockSection__notification LTOItem__notification"
                                    >
                                        <span>Thank you for your request, we will get back to you shortly</span>
                                    </NotificationPopup>
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className="LTOItem__footer">
                    <div
                        className="LTOItem__date"
                        data-testid="textLtoDate"
                    >
                        {getFormattedDate(date)}
                    </div>
                    <div
                        className="LTOItem__price"
                        data-testid="textLtoPrice"
                    >
                        {price}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(LTOItem);
