import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/SpriteIcon/SpriteIcon';
import getFileTypeIcon from '../../core/getFileTypeIcon';
import { ellipsis } from 'react-multiline-ellipsis';
import analytics from '../../core/analytics';

class Title extends Component {
    render() {
        const { text } = this.props;
        const classList = new Set(['search-popup__result-title']);

        return (
            <div className={Array.from(classList).join(' ')}>
                <span className="search-popup__result-title-inner">
                    {text}
                </span>
            </div>
        );
    }
}

Title.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string
};

class SearchCategoryItemDoc extends Component {

    renderTitle() {
        const { title } = this.props;
        const WrapTitle = ellipsis(Title, 2);
        return (
            <WrapTitle
                text={title}
                title={title}
            />
        );
    }

    renderSubTitle() {
        if (this.props.subTitle) {
            return (
                <div className="search-popup__result-subtitle search-popup__result-subtitle--not-capitalized">
                    {this.props.subTitle}
                </div>
            );
        }

        return null;
    }

    renderIcon() {
        const { fileExtention = '', type } = this.props;
        let iconName;
        const probableIconName = fileExtention.substr(1);

        if (type === 'project') {
            iconName = 'project-icon';
        }
        else {
            iconName = getFileTypeIcon(probableIconName);
        }

        return (
            <Icon iconId={`${iconName}-bw`} className="search-popup__results-icon"/>
        );
    }

    renderProjectName() {
        const { projects } = this.props;
        if (projects !== '') {
            return (
                <div className="search-popup__result-subtitle font-search">
                    {projects}
                </div>
            );
        }

        return '';
    }

    handleClick() {
        const { categoryTitle, query, index, title } = this.props;
        analytics.sendOpenResultByPlaceInSearch(`${categoryTitle} | ${query} | ${index + 1} | ${title}`);
    }

    render() {
        const { testId } = this.props;
        return (
            <Link
                className="search-popup__category-result" to={this.props.url}
                onClick={this.handleClick.bind(this)}
                data-testid={testId}
            >
                {this.renderIcon()}
                <div className="search-popup__result-text">
                    {this.renderTitle()}
                    {this.renderProjectName()}
                    {this.renderSubTitle()}
                </div>
            </Link>
        );
    }
}

SearchCategoryItemDoc.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    url: PropTypes.object,
    addLink: PropTypes.bool,
    fileExtention: PropTypes.string,
    projects: PropTypes.string,
    id: PropTypes.any
};

export default SearchCategoryItemDoc;
