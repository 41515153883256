import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import analytics from '../../core/analytics';
import {
    openSearchPopupCategory
} from '../../actions/searchPopup.actions';
import {
    setDateFilter,
} from '../../actions/documents.actions';
import ResultsCategoryItem from './ResultsCategoryItem';
import ResultsCategoryItemDoc from './ResultsCategoryItemDoc';
import { capitalize } from "@core/old_helpers";

class ResultsCategory extends Component {
    renderItems() {
        const { useLinks, name, section, query, items, type, title } = this.props;
        if (name === 'DocumentItem') {
            return items.map((item, index) => (
                <ResultsCategoryItemDoc
                    key={index}
                    index={index}
                    categoryTitle={title}
                    {...item}
                    query={query}
                    isMain={index === 0}
                    addLink={useLinks}
                    className={`search-popup-category--${type}__result`}
                    testId={`buttonSearchPopupGroupResult${index}`}
                />
            ));
        }
        return items.map((item, index) => (
            <ResultsCategoryItem
                key={index}
                index={index}
                categoryTitle={title}
                {...item}
                query={query}
                isMain={index === 0}
                addLink={useLinks}
                section={section}
                className={`search-popup-category--${type}__result`}
                testId={`buttonSearchPopupGroupResult${index}`}
            />
        ));
    }

    renderCategoryButtonOpen(title) {
        const { name, openCategory, query, selectedFilter, openCategoryButtonText = 'more', } = this.props;
        let { viewMoreUrl = '', href = '' } = this.props;

        if (href !== '') {
            return (
                <a
                    target="_blank"
                    href={href}
                    className="search-popup__open-category-button"
                    data-testid={`buttonSearchPopupGroupResult${capitalize(title, true)}More`}
                >
                    {openCategoryButtonText}
                </a>
            )
        }
        else if (viewMoreUrl !== '') {
            // const shouldSaveFilter = (name.toLowerCase() === 'documentitem' && viewMoreUrl.indexOf('myDocuments') === -1)
            //     || name.toLowerCase() === 'trendspottingandreportitem';
            // if (shouldSaveFilter) {
            //     viewMoreUrl += `&f=${selectedFilter.id}`;
            // }

            return (
                <Link
                    to={viewMoreUrl}
                    className="search-popup__open-category-button"
                    onClick={
                        () => {
                            analytics.sendSearchMoreInCategory(`${title} | more | ${query}`);
                            if (name === 'DocumentItem' || name === 'TrendspottingAndReportItem') {
                                this.props.setDateFilter(this.props.selectedFilter);
                            }
                        }
                    }
                    data-testid={`buttonSearchPopupGroupResult${capitalize(title, true)}More`}
                >
                    {openCategoryButtonText}
                </Link>
            );
        }
        return (
            <button
                className="search-popup__open-category-button"
                onClick={
                    () => {
                        analytics.sendSearchMoreInCategory(`${title} | more | ${query}`);
                        openCategory(name);
                    }
                }
            >
                more
            </button>
        );
    }

    render() {
        const { title, isOpened, name, items, className, type } = this.props;
        const classList = new Set([
            'search-popup__category',
            'search-popup-category',
            `search-popup-category--${type}`,
            className.split(' ')
        ]);

        const shouldRenderButton = name.toLowerCase() !== 'foodprofilesitem' &&
            name.toLowerCase() !== 'instantchartsandmacsitem';

        if (isOpened) {
            classList.add('search-popup-category--opened');
        }

        classList.add('search-popup-category--loading');

        if (name === 'DocumentItem') {
            classList.add('my-docs-results');
        }

        return (
            <div className={Array.from(classList).join(' ')}>
                <div
                    className="search-popup-category__title"
                    data-testid={`labelSearchPopupCategoryTitle${capitalize(title, true)}`}
                >
                    {title}
                    {!isOpened && shouldRenderButton && this.renderCategoryButtonOpen(title)}
                </div>
                <div
                    className={`search-popup-category__items search-popup-category--${type}__results`}
                    data-testid={`selectSearchPopupGroupResults${capitalize(title, true)}`}
                >
                    {
                        items.length > 0
                            ? this.renderItems()
                            : <div className="search-popup__result-title ">No matching {title.toLowerCase()}.</div>
                    }
                </div>
            </div>
        );
    }
}

ResultsCategory.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    markMatches: PropTypes.bool,
    items: PropTypes.array,
    total: PropTypes.number,
    openCategory: PropTypes.func,
    isOpened: PropTypes.bool,
    isOpenable: PropTypes.bool,
    useLinks: PropTypes.bool,
    section: PropTypes.string,
    query: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    viewMoreUrl: PropTypes.string
};

const mapStateToProps = state => ({
    query: state.searchPopup.query,
});

const mapDispatchToProps = dispatch => ({
    openCategory: categoryName => dispatch(openSearchPopupCategory(categoryName)),
    setDateFilter: data => dispatch(setDateFilter(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsCategory);
