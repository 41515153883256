import dayjs from "dayjs";
import { SortDirectionEnum } from "./enums";

export function deepCopy<T>(data: T): T {
    return JSON.parse(JSON.stringify(data));
}

export function deepEqual<A, B>(a: A, b: B): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
}

export function getFormattedThousand(number: number = 0, separator: string = ','): string {
    if (number < 0) return `-${getFormattedThousand(-number)}`;
    let numberString = Math.trunc(number).toString();
    let result = "";
    while (numberString.length > 3) {
        result = `${separator}${numberString.substr(numberString.length - 3)}${result}`;
        numberString = numberString.substr(0, numberString.length - 3);
    }

    result = `${numberString}${result}`;
    return result;
}

export function getMedianValue(arr: number[]): number {
    const arrCopy = deepCopy(arr);

    arrCopy.sort((a, b) => a > b ? -1 : 1);

    if (arrCopy.length % 2 === 1) {
        return arrCopy[Math.floor(arrCopy.length/2)];
    } else {
        return (arrCopy[arrCopy.length/2 - 1] + arrCopy[arrCopy.length/2])/2;
    }
}

export function getDecimal(n: number): number {
    const int = parseInt(`${n}`);
    return n - int;
}

export function getDecimalAfterPoint (n: number, decimalSize: number = 2): string {
    return getDecimal(n).toFixed(decimalSize).replace(/^-?0./, '');
}

export function getNumberWithDecimal(n: number, decimalSize: number = 1): string {
    const points = getDecimalAfterPoint(n, decimalSize);
    return Number.parseInt(points) ? `${n}` : `${n}.${points}`;
}

export function getFormattedThousandWithDecimal(n: number, decimalSize: number = 2): string {
    return `${getFormattedThousand(n)}.${getDecimalAfterPoint(n, decimalSize)}`;
}

export function getFormattedCurrency(number: number, separator: string = ',', currency = "$") {
    return `${currency}${getFormattedThousand(parseInt(`${number}`), separator)}.${getDecimalAfterPoint(number)}`;
}

export function equalInLC(string1: string, string2: string) {
    return string1.toLowerCase() === string2.toLowerCase();
}

export function includesInLC(string1: string, string2: string) {
    return string1.toLowerCase().includes(string2.toLowerCase());
}

export function startsFromInLC(string1: string, string2: string) {
    return string1.toLowerCase().indexOf(string2.toLowerCase()) === 0;
}

export const StringMatchRanks = {
    Exact: 100,
    Starts: 10,
    Includes: 1,
    NotIncludes: 0,
};

export function getStringMatchRank (value: string, query: string, lowerRanks: boolean = false) {
    if (equalInLC(value, query)) {
        return lowerRanks ? StringMatchRanks.Exact / 2 : StringMatchRanks.Exact;
    }
    else if (startsFromInLC(value, query)) {
        return lowerRanks ? StringMatchRanks.Starts / 2 : StringMatchRanks.Starts;
    }
    else if (includesInLC(value, query)) {
        return lowerRanks ? StringMatchRanks.Includes / 2 : StringMatchRanks.Includes;
    }
    else {
        return StringMatchRanks.NotIncludes;
    }
}

export function splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
    return Array(Math.ceil(array.length / chunkSize))
        .fill(null)
        .map((_, index) => index * chunkSize)
        .map((begin) => array.slice(begin, begin + chunkSize));
}

export function splitRows<T>(array: T[], rowsCount: number): T[][] | null {
    if (rowsCount <= 0) return null;

    const rowLength = Math.ceil(array.length / rowsCount);

    return Array(rowsCount).fill(null).map((_, index) => array.slice(index * rowLength, (index + 1) * rowLength));
}

export function splitColumns<T>(array: T[], columns: number): T[][] {
    const columnArrays: T[][] = Array(columns).fill(null).map(() => []);

    array.forEach((el, index) => {
        const columnIndex = index % columns;
        columnArrays[columnIndex] = columnArrays[columnIndex] || [];
        columnArrays[columnIndex].push(el);
    });

    return columnArrays;
}

export function throttle(func: Function, limit: number): Function {
    let inThrottle: boolean;

    return function (this: any): any {
        const args = arguments;
        const context = this;

        if (!inThrottle) {
            inThrottle = true;
            func.apply(context, args);
            setTimeout(() => (inThrottle = false), limit);
        }
    };
}

export function toggleInArray<T>(array: T[], ...values: T[]) {
    let result = [...array];
    if (values.length === 0) return [];
    values.forEach((value) => {
        if (array.includes(value)) {
            result = result.filter((item) => item !== value);
        } else {
            result = [...result, value];
        }
    });
    return result;
}

export function addToArray<T>(array: T[], values: T[]) {
    let result = [...array];
    if (values.length === 0) return array;
    values.forEach((value) => {
        if (!array.includes(value)) {
            result = [...result, value];
        }
    });
    return result;
}

export function removeFromArray<T>(array: T[], values: T[]) {
    let result = [...array];
    if (values.length === 0) return array;
    values.forEach((value) => {
        if (array.includes(value)) {
            result = result.filter((item) => item !== value);
        }
    });
    return result;
}

export const getLtoValue = (value: string) => {
    if (!value) return "-";
    const newValue = value.replace(/--/, "-");
    return newValue === "-" ? newValue : `${newValue}%`;
};

const ua = window.navigator.userAgent;
export const isIE = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;

export function prepareForMatching(str: string) {
    return str.trim().replace(/\s+/gm, ' ').toLowerCase();
}

export function getEnding(title: string, count: number) {
    const clearedTitle = prepareForMatching(title);
    switch(clearedTitle) {
        default: {
            return `${title}${count === 1 ? '' : 's'}`;
        }
    }
}

export const sortByKey = (key: string, sortDirection: SortDirectionEnum = SortDirectionEnum.ASC) => {
    return (a: any, b: any) => {
        const res = (a[key] < b[key]) ? -1 : (a[key] > b[key]) ? 1 : 0;
        return res * sortDirection;
    }
}

export function getUnique<T>(arr: T[]): T[] {
    return Array.from(new Set(arr));
}

export function getSelectedCounterString(selectedItems: any[], allItems: any[]): string {
    return allItems.length === selectedItems.length ? 'all' : `${selectedItems.length} of ${allItems.length}`
}

export function prepareForSorting(value: any): string | number {
    switch (typeof value) {
        case "string": return value.toLowerCase();
        case "boolean": return value ? 1 : 0;
        default: return value;
    }
}

export function sanitizeHtml (str: string): string {
    const div = document.createElement('div');
    div.innerHTML = str;
    return div.innerText;
}

export function sanitizeForSearch (str: string): string {
    return str.replace(/[^a-zA-Z0-9 ]/gi, '');
}

export function getFormattedDate(currentDate: string, template: string = "MMMM YYYY") {
    return dayjs(currentDate).format(template);
}

type RequiredFields = {
    title?: string;
    subtitle?: string;
}

export const filterDataBySearch = <T extends RequiredFields>(data: T[], searchQuery: string): T[] => {
    const trimmedSearchQuery = searchQuery.trim();
    if (!trimmedSearchQuery) return data;
    const isIncludes = (str: string) => str.toLowerCase().includes(searchQuery.toLowerCase());
    return data.filter((item) => isIncludes(item.title || '') || isIncludes(item.subtitle || ''));
};
export function sortByField<T>(field: keyof T, direction: 'asc' | 'desc' = 'asc'): (a: T, b:T) => number {
    const factor = direction === 'asc' ? 1 : -1;
    return (a: T, b: T) => a[field] > b[field] ? factor : -factor;
}

export function getValueWithSign (value: number): string {
    return `${value > 0 ? '+' : ''}${value}`;
}

export function cutDomainName(url: string) {
    return url.split('/')[2];
}

export function downloadFile(url: string, filename?: string): void {
    const link = document.createElement('a');

    if (typeof link.download === 'undefined') {
        window.location.href = url;
    } else {
        link.href = url;
        link.download = filename || "true";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

type NavigatorIncludesMS = Navigator & { msSaveBlob?: (blob: Blob, filename?: string) => void };

function checkIsTrustedBlobDownloadUrl(url: string): boolean {
    return url.indexOf('blob:' + window.location.origin) === 0;
}

export function downloadBlobFile(blob: Blob, filename?: string) {
    const navigator = window.navigator as NavigatorIncludesMS;

    if (navigator.msSaveBlob && typeof navigator.msSaveBlob !== 'undefined') {
        navigator.msSaveBlob(blob, filename);
    } else {
        let URL = window.URL || window.webkitURL;
        const downloadUrl = URL.createObjectURL(blob);

        if (checkIsTrustedBlobDownloadUrl(downloadUrl)) {
            debugger
            if (filename) {
                downloadFile(downloadUrl, filename);
            } else {
                window.location.href = downloadUrl;
            }

        }

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
        }, 100);
    }
}

export const getFilenameFromContentDisposition = (disposition: string | null): string => {
    let filename = "";
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        const matches = disposition.match(filenameRegex);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
    }

    return filename;
}

export const getUrlWithHttp = (url: string) => {
    if (!url.trim()) return url;
    return url.includes("http") ? url : `http://${url}`
};

export function capitalize(str: string): string {
    str = str.toLowerCase();
    return str.replace(
        /\w\S*/g,
        txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}


export function uniteArraysUnique(a1: any[], a2: any[]) {
    return Array.from(new Set([...a1, ...a2]));
}

export function minusArrayUnique(a1: any[], a2: any[]) {
    return a1.filter(i => !a2.includes(i));
}

export function sanitizeUrl(url?: string): string {
    if (!url) return "";
    const urlWithoutDuplicateSlashes = url.replace(/([^:]\/)\/+/g, "$1");
    return getUrlWithHttp(urlWithoutDuplicateSlashes);
}
export function getRoundedToSign(num: number, decimalsCount: number = 1): number {
    const factor = 10 ** decimalsCount;
    return Math.round(num * factor) / factor;
}


export function getDivisionRemainder(base: number, n: number): number {
    if (base == 0) return 0;
    return n - Math.floor(n / base) * base;
}

export function isDivisionRemainder (base: number, n: number, expectedRemainder: number): boolean {
    return getDivisionRemainder(base, n) === expectedRemainder;
}

export function getQueryRegExp (q: string) {
    return new RegExp(`(${prepareForMatching(q)})`, 'gi');
}

export function hasMatches(str: string, q: string): boolean {
    if (prepareForMatching(q) === '') return false;
    return getQueryRegExp(q).test(prepareForMatching(str));
}
export function highlightMatches(
    str: string,
    q: string,
    wrapMatchFn: (str: string) => string,
): string {
    if (prepareForMatching(q) === '') return str;
    return str.replace(getQueryRegExp(q), wrapMatchFn);
}