import { ColumnIconType } from "../../components/new/Table/useTableSort";
import { iTableConfigItem } from "../../components/new/Table/Table.helpers";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { routeNames } from "../../core/routeNames";
import Button from "../../components/new/Button/Button";
import { API__ChainMenuAndLtosViewModel, API__MenuItem } from "../../core/apiSchema";
import analytics from "../../core/analytics";

const MenuItem: FC<API__MenuItem> = (
    {
        title,
        description,
    }
) => {
    return (
        <div className="WhoOffersMenuItem">
            <div
                className="WhoOffersMenuItem__title"
                dangerouslySetInnerHTML={{
                    __html: title
                }}
            />
            {description && (
                <div
                    className="WhoOffersMenuItem__description"
                    dangerouslySetInnerHTML={{
                        __html: description
                    }}
                />
            )}
        </div>
    );
};

export function useTableConfig(
    data: API__ChainMenuAndLtosViewModel[],
    sortingField: keyof API__ChainMenuAndLtosViewModel | null,
    sortingDirection: 'asc' | 'desc',
    handleSort: (fieldName: keyof API__ChainMenuAndLtosViewModel) => () => void,
    getColumnIcon: ColumnIconType,
    // expandedRowIndex: number,
    // setExpandedRowIndex: (i: number) => void,
): iTableConfigItem[] {
    const columnIcon = useCallback(
        (fieldName: keyof API__ChainMenuAndLtosViewModel) => getColumnIcon(fieldName, sortingField, sortingDirection),
        [getColumnIcon, sortingDirection, sortingField]
    );
    const [expandedRowIndexes, setExpandedRowIndexes] = useState<number[]>([]);

    useEffect(() => {
        setExpandedRowIndexes([]);
    }, [sortingField, sortingDirection]);

    return useMemo((): iTableConfigItem[] => [
        {
            width: 300,
            title: 'Chain',
            icon: columnIcon('chainName'),
            isActive: sortingField === 'chainName',
            styles: {
                cell: {
                    position: 'sticky',
                    top: 0,
                    bottom: 0,
                }
            },
            Value: ({ index }) => (
                <Link
                    className="NewTopChains__link"
                    to={{
                        pathname: routeNames.chainDetails,
                        search: `id=${data[index].chainId}`,
                    }}
                >
                    {data[index].chainName}
                </Link>
            ),
            handleHeaderClick: handleSort('chainName'),
        },
        {
            title: 'Menu items',
            styles: { cell: { height: 'auto', minHeight: 40 } },
            Value: ({ index }) => (
                <div className="WhoOffers__menu-items">
                    {!expandedRowIndexes.includes(index) && (
                        <MenuItem
                            {...data[index].menuItems[0]}
                        />
                    )}
                    {expandedRowIndexes.includes(index) && data[index].menuItems.map(i => (
                        <MenuItem
                            key={i.id}
                            {...i}
                        />
                    ))}
                </div>
            )
        },
        {
            width: 130,
            title: '',
            Value: ({ index }) => (
                <>
                    {data[index].menuItems.length > 1 && (
                        <>
                            {expandedRowIndexes.includes(index)
                                ? (
                                    <Button
                                        modifiers={["secondary"]}
                                        onClick={() => setExpandedRowIndexes([...expandedRowIndexes.filter(i => i !== index)])}
                                    >
                                        View less
                                    </Button>
                                )
                                : (
                                    <Button
                                        modifiers={["secondary"]}
                                        onClick={() => setExpandedRowIndexes([...expandedRowIndexes, index])}
                                    >
                                        plus {data[index].menuItems.length - 1} more
                                    </Button>
                                )}
                        </>
                    )}
                </>
            )
        }
    ], [columnIcon, handleSort, data, sortingField, expandedRowIndexes])
}