import { FC, useContext, useEffect, useState } from "react";
import qs from "query-string";
import Button from "../../components/new/Button/Button";
import NewTopChains from "./NewTopChains";
import Page from "../../containers/Page/Page";
import PageContent from "../../containers/PageContent/PageContent";
import Loader from "../../components/new/Loader/Loader";
import { getFormattedThousand } from "../../core/helpers";
import usePlaces from "../../core/apiHooks/usePlaces";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../AppContext";
import WhoOffers from "./WhoOffers";
import { useOfferingChains } from "../../core/apiHooks/useOfferingChains";
import Tabs from "../../components/new/Tabs/Tabs";
import { ID } from "../../core/models";
import { ChainsFilters, iChainsFilters } from "../../core/models/ChainsFilters";
import { useChainsExport } from "../../core/apiHooks/useChainsExport";
import NotificationPopup from "../../components/NotificationPopup/NotificationPopup";
import analytics from "../../core/analytics";

const Places: FC = () => {
    const {
        globalVariables: {
            hasFFChainsFeaturePermission: hasPermission,
        },
    } = useContext(AppContext);
    const location = useLocation();
    const parsedSearch = qs.parse(location.search);
    const searchQuery = parsedSearch.q as string;
    const possibleTabIndex = +(parsedSearch.tabIndex as string);
    const [tabIndex, setTabIndex] = useState<ID>(possibleTabIndex || 1);
    const { isLoading, data, } = usePlaces(searchQuery);
    const [selectedFilters, setSelectedFilters] = useState<iChainsFilters>(new ChainsFilters());
    const [selectedChainsIds, setSelectedChainsIds] = useState<number[]>([]);

    const {
        requestExportChains,
        isDownloading,
        isNotificationShow,
    } = useChainsExport(selectedChainsIds, data.filtersData, selectedFilters, searchQuery);

    const handleFiltersChange = (selectedFilters: iChainsFilters, selectedChainsIds: number[]) => {
        setSelectedFilters(selectedFilters);
        setSelectedChainsIds(selectedChainsIds)
    };

    const {
        isLoading: isWhoOffersLoading,
        data: whoOffersData,
    } = useOfferingChains(searchQuery);

    const [count, setCount] = useState(0);

    const isCurrentTabContentLoading = (tabIndex === 1 && isLoading) || (tabIndex === 2 && isWhoOffersLoading);
    const title = tabIndex === 1
        ?<>{getFormattedThousand(count)} matching chains</>
        : <>these chains feature&nbsp;<span>{searchQuery}</span>&nbsp;on their menu</>;

    useEffect(() => {
        analytics.sendPlacesPageView(searchQuery);
    }, []);

    return (
        /* @ts-ignore */
        <Page>
            <PageContent>
                <div className="NewTopChains is-places">
                    <NotificationPopup
                        isOpened={isNotificationShow}
                        hasCloseButton={false}
                        style={{ width: "auto", height: "auto" }}
                        className="notification-popup--page"
                    >
                        <span>Report is ready to save...</span>
                    </NotificationPopup>
                    <div className="NewTopChains__header">
                        <div className="NewTopChains__part">
                            {!isCurrentTabContentLoading && title}
                        </div>
                        <div className="NewTopChains__part">
                            <div className="NewTopChains__tabs">
                                <Tabs
                                    selectedId={tabIndex}
                                    onChange={setTabIndex}
                                    items={[
                                        { id: 1, title: 'Places' },
                                        { id: 2, title: 'Who offers it' },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="NewTopChains__header">
                        <div className="NewTopChains__part"/>
                        <div className="NewTopChains__part">
                            {tabIndex === 1 && hasPermission && (
                                <Button
                                    className="NewTopChains__button"
                                    modifiers={["primary", "accent"]}
                                    onClick={requestExportChains}
                                    isLoading={isDownloading}
                                >
                                    XLS Export
                                </Button>
                            )}
                        </div>
                    </div>
                    {tabIndex === 1 && (
                        <>
                            {isLoading && <Loader/>}
                            {!isLoading && (
                                <NewTopChains
                                    data={data}
                                    setCount={setCount}
                                    handleFiltersChange={handleFiltersChange}
                                />
                            )}
                        </>
                    )}
                    {tabIndex === 2 && (
                        <>
                            {isWhoOffersLoading && <Loader/>}
                            {!isWhoOffersLoading && (
                                <WhoOffers
                                    setCount={setCount}
                                    data={whoOffersData}
                                />
                            )}
                        </>
                    )}
                </div>
            </PageContent>
        </Page>
    );
};

export default Places;