import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import analytics from '../../../core/analytics';
import { makeEnding } from '../../../core/old_helpers';

import SpriteIcon from '../../../components/SpriteIcon/SpriteIcon';
import { setSharePopupState, } from '../../../actions/documents.actions';
import { addNotification } from '../../../components/GlobalNotification/globalNotification.actions';
import { addFileToPackage } from '../../../actions/packages.actions';
import PackageAddWrapper from '@components/PackageAddWrapper/PackageAddWrapper';
import './MacItemStyle.scss'
import { capitalize } from "@datassential/platform-ui-lib";

const btns = [
    {
        id: 0,
        text: 'View',
        iconId: 'explore',
        isLink: true,
        title: 'open this MAC in SNAP'
    },
    {
        id: 1,
        text: 'Download',
        iconId: 'download'
    },
    {
        id: 2,
        text: 'Package',
        iconId: 'add-to-package',
        isEnabled: true,
        title: 'add this item to a package; you can then share your package with colleagues'
    },
    {
        id: 3,
        text: 'Share',
        iconId: 'share'
    },
    {
        id: 4,
        text: 'Link',
        iconId: 'link',
        isCopyLink: true
    }
];

class MacItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadActionsVisible: false,
            selectedFileExtention: null
        };
    }

    renderBtns(callbacks) {
        return btns.map((item, index) => {
            const classList = new Set([
                'mac-item__btn',
                'mac-item__btn--no-margin',
                item.iconId === 'download' ? 'mac-item__btn--blue' : '',
                item.isLink ? 'mac-item__btn-link' : ''
            ]);
            if (!((this.props.isSingle && item.isLink) || (item.isCopyLink && !this.props.tableMode))) {
                return (
                    item.isLink ?
                        (
                            <Link
                                to={this.props.from === 'allMacs' ?
                                    {
                                        pathname: '/view-document',
                                        search: `?id=${this.props.instanceId}&doctype=${this.props.documentType}&from=${this.props.from}`
                                    } :
                                    {
                                        pathname: '/view-document',
                                        search: `?id=${this.props.instanceId}&doctype=${this.props.documentType}&fromDocuments=${encodeURIComponent(this.props.searchWord)}`
                                    }}
                                className={Array.from(classList).join(' ')}
                                key={index}
                                title={item.title}
                                onClick={callbacks[index].method}
                                data-testid={`buttonMacTile${capitalize(item.text, true)}`}
                            >
                                <span className="mac-item__btn-icon">
                                    <SpriteIcon iconId={item.iconId} className="btn-icon-svg"/>
                                </span>
                                <p className="mac-item__btn-text" data-testid={`textMacTile${capitalize(item.text, true)}Button`}>
                                    {item.text}
                                </p>
                            </Link>
                        ) : (
                            <div
                                className={Array.from(classList).join(' ')}
                                key={index}
                                onClick={callbacks[index].method}
                                data-testid={`buttonMacTile${capitalize(item.text, true)}`}
                            >
                                <div
                                    className={item.iconId === 'download' ? 'mac-item__btn--download' : ''}
                                    title={item.title}
                                >
                                    {
                                        item.iconId === 'add-to-package' ?
                                            (
                                                <PackageAddWrapper>
                                                        <span className="mac-item__btn-icon">
                                                            <SpriteIcon iconId={item.iconId} className="btn-icon-svg"/>
                                                        </span>
                                                    <p className="mac-item__btn-text"  data-testid={`textMacTile${capitalize(item.text, true)}Button`}>
                                                        {item.text}
                                                    </p>
                                                </PackageAddWrapper>
                                            ) :
                                            (
                                                <div>
                                                        <span className="mac-item__btn-icon">
                                                            <SpriteIcon iconId={item.iconId} className="btn-icon-svg"/>
                                                        </span>
                                                    <p className="mac-item__btn-text"  data-testid={`textMacTile${capitalize(item.text, true)}Button`}>
                                                        {item.text}
                                                    </p>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        )
                );
            }
            return '';
        });
    }

    btnsOnClickCallbacks() {
        const context = this;
        return [
            {
                key: 'view',
                method: () => {
                    analytics.sendDocumentsTabItemBtn('View');
                }
            },
            {
                key: 'download',
                method: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const keys = Object.keys(context.props.downloadLinks);
                    if (keys.length <= 1) {
                        window.location.href = context.props.downloadLinks[keys[0]];
                    } else {
                        context.setState({ downloadActionsVisible: true });
                    }
                }
            },
            {
                key: 'add-to-package',
                method: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.props.addFileToPackage({
                        itemId: this.props.instanceId,
                        itemDocumentType: this.props.docType
                    });
                    analytics.sendDocumentsTabItemBtn('Package');
                }
            },
            {
                key: 'share',
                method: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    context.props.showPopup({
                        id: context.props.instanceId,
                        documentType: context.props.documentType,
                        shareLinkString: context.props.shareLinkString,
                        publicationFamily: context.props.publicationFamily,
                        title: context.props.title,
                        popupType: 'allDocuments'
                    });
                    analytics.sendDocumentsTabItemBtn('Share');
                }
            },
            {
                key: 'link',
                method: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.copyToClipboard();
                    analytics.sendDocumentsTabItemBtn('Link');
                }
            }
        ];
    }

    selectfileExtention(extention) {
        this.setState({ selectedFileExtention: extention });
    }

    handleMouseLeave(event) {
        this.props.handleMouseLeave && this.props.handleMouseLeave();
        const target = event.target;
        const parent = target.parentNode;
        const backBtnClass = 'mac-item__btn--back';

        // fix for Chrome to prevent loosing of hover when click on
        // buttons and btns-block is being re-rendered
        if (!target.classList.contains(backBtnClass) && !parent.classList.contains(backBtnClass)) {
            this.setState({
                downloadActionsVisible: false,
                selectedFileExtention: null,
            });
        }
    }

    renderDownloadActions() {
        const { title, downloadLinks } = this.props;

        return (
            <div className="mac-item__download-btns">
                <button
                    className="mac-item__btn mac-item__btn--back"
                    onClick={this.goBackFromDownload.bind(this)}
                    data-testid="buttonMacTileGoBackFromDownload"
                >
                    <span className="mac-item__btn-icon">
                        <SpriteIcon iconId="back_arrow" className="btn-icon-svg"/>
                    </span>
                    <p
                        className="mac-item__btn-text"
                        data-testid="textMacTileGoBackFromDownloadButton"
                    >
                        go back
                    </p>
                </button>
                    <button
                        className="mac-item__btn"
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = `${process.env.REACT_APP_API + downloadLinks[1].linkToDownload}`;
                            link.download = 'true';
                            link.click();
                            analytics.sendDownloadMAC(`${title} [Wide ppt]`)
                        }}
                        data-testid="buttonMacTileDownloadPpt"
                    >
                        <span className="mac-item__btn-icon">
                            <SpriteIcon iconId="icon-ppt" className="btn-icon-svg"/>
                        </span>
                        <p className="mac-item__btn-text" data-testid="textMacTileDownloadPptButton">
                            Download PPT
                        </p>
                    </button>
                    <button
                        className="mac-item__btn"
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = `${process.env.REACT_APP_API + downloadLinks[3].linkToDownload}`;
                            link.download = 'true';
                            link.click();
                            analytics.sendDownloadMAC(`${title} [Wide image]`)
                        }}
                        data-testid="buttonMacTileDownloadJpeg"
                    >
                        <span className="mac-item__btn-icon">
                            <SpriteIcon iconId="sample" className="btn-icon-svg"/>
                        </span>
                        <p className="mac-item__btn-text" data-testid="textMacTileDownloadJpegButton">
                            Download JPEG
                        </p>
                    </button>
            </div>);
    }

    goBackFromDownload() {
        this.setState({ downloadActionsVisible: false });
    }

    copyToClipboard(e) {
        if (e) e.preventDefault();
        const dummy = document.createElement('input');
        document.body.appendChild(dummy);
        dummy.setAttribute('id', 'dummy_id');
        document.getElementById('dummy_id').value = this.props.shareLinkString;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        this.props.showNotification({
            text: 'Link copied to your clipboard. You can paste it into email or any else you need.',
            duration: 3000,
        });
    }

    makeDescriptionText() {
        const rawList = this.props.description.split('<');
        const wordsList = [];
        let text;
        rawList.forEach((elem) => {
            const word = elem.split('|')[0].trim();
            wordsList.push(word);
        });
        if (wordsList.length === 1) {
            text = `includes ${wordsList[0]}`;
        } else if (wordsList.length === 2) {
            text = `includes ${wordsList[0]}, ${wordsList[1]}`;
        } else {
            text = `includes ${wordsList[0]}, ${wordsList[1]},
         and more`;
        }
        text = text.toLowerCase();

        return { text, wordsAmount: wordsList.length };
    }

    render() {
        const { thumnailUrl, title, subTitle, isSingle } = this.props;
        const classList = new Set([
            'mac-item',
            !isSingle ? 'animate--slide-in-up' : '',
            isSingle ? 'mac-item--single' : '',
            this.state.downloadActionsVisible || this.state.fileExtention ? 'mac-item--hovered' : '',
            this.props.tableMode ? 'mac-item--in-table' : ''
        ]);
        const description = this.makeDescriptionText();

        return (
            <div
                className={Array.from(classList).join(' ')}
                onMouseEnter={this.props.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave.bind(this)}
            >
                {!this.props.tableMode ?
                    (
                        <div
                            className="mac-item__inner scroll-to--js"
                            data-testid={`blockMacTile-${this.props.index}`}
                        >
                            <Link
                                to={this.props.from === 'allMacs' ?
                                    {
                                        pathname: '/view-document',
                                        search: `?id=${this.props.instanceId}&doctype=${this.props.documentType}&from=${this.props.from}`
                                    } :
                                    {
                                        pathname: '/view-document',
                                        search: `?id=${this.props.instanceId}&doctype=${this.props.documentType}&fromDocuments=${encodeURIComponent(this.props.searchWord)}`
                                    }}
                                className="mac-item__content disabled-underline"
                                data-testid="linkMacTile"
                            >
                                <span
                                    className="mac-item__link"
                                    title="create an easy link to this document you can share with others"
                                    onClick={this.copyToClipboard.bind(this)}
                                    data-testid="buttonMacTileCopyLink"
                                >
                                    Copy link
                                </span>
                                <div
                                    className="mac-item__title" title={title}
                                    data-testid="textMacTileTitle"
                                >
                                    {title}
                                </div>
                                <div
                                    className="mac-item__sub-title"
                                    data-testid="textMacTileSubTitle"
                                >
                                    {`${subTitle.split('·')[0]} · ${description.wordsAmount} ${makeEnding('item', description.wordsAmount)}`}
                                </div>
                                <div className="mac-item__image-block">
                                    <img
                                        src={thumnailUrl}
                                        alt={title}
                                        className="mac-item__image"
                                        data-testid="imgMacTileImage"
                                    />
                                </div>
                                <div
                                    className="mac-item__text content"
                                    data-testid="textMacTileDescription"
                                >
                                    {description.text}
                                </div>
                            </Link>
                            <div className="mac-item__btns-block">
                                {
                                    this.state.downloadActionsVisible ?
                                        this.renderDownloadActions() :
                                        this.renderBtns(this.btnsOnClickCallbacks.call(this))
                                }
                            </div>
                        </div>
                    ) :
                    (
                        <div className="mac-item__btns-block">
                            {
                                this.state.downloadActionsVisible ?
                                    this.renderDownloadActions() :
                                    this.renderBtns(this.btnsOnClickCallbacks.call(this))
                            }
                        </div>
                    )
                }
            </div>
        );
    }
}

MacItem.propTypes = {
    index: PropTypes.number,
    className: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.any,
    thumnailUrl: PropTypes.string,
    showNotification: PropTypes.func,
    id: PropTypes.number,
    documentType: PropTypes.number,
    isSingle: PropTypes.bool,
    searchWord: PropTypes.string,
    fileExtention: PropTypes.string,
    downloadLinks: PropTypes.array,
    shareLinkString: PropTypes.string,
    tableMode: PropTypes.bool,
    handleMouseEnter: PropTypes.func,
    handleMouseLeave: PropTypes.func,
    hasPermission: PropTypes.bool,
    addFileToPackage: PropTypes.func,
    from: PropTypes.string,
    docType: PropTypes.string,
    instanceId: PropTypes.number
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    showPopup: data => dispatch(setSharePopupState(data)),
    showNotification: text => dispatch(addNotification(text)),
    addFileToPackage: options => dispatch(addFileToPackage(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(MacItem);
