import { Component } from 'react';
import Popup from '../Popup/Popup';
import './DarkModal.styles.scss';

export default class DarkModal extends Component {
    render() {
        const {
            children,
            textAlign = 'left',
            onClose,
            isOpened,
            className,
            hasCloseButton = true,
        } = this.props;
        return (
            <Popup
                onClose={onClose}
                isOpened={isOpened}
            >
                <div className={`DarkModal ${className}`}>
                    <div className={`DarkModal__inner  DarkModal__inner--align-${textAlign}`}>
                        {children}
                        {hasCloseButton && (
                            <button
                                onClick={onClose}
                                className="DarkModal__close-button"
                            />
                        )}
                    </div>
                </div>
            </Popup>
        );
    }
}
