import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { capitalize } from '../../core/old_helpers';
import { Link } from 'react-router-dom';
import Icon from '../../components/SpriteIcon/SpriteIcon';

import {
    hasFoodsSelector,
    hasChainsSelector,
    hasDocsSelector,
    chainsWhoOffersCountSelector,
} from '../../selectors/searchPopup.selectors';

import ResultsCategory from './ResultsCategory';
import ColumnsList from './ColumnsList';

class Results extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDropdownOpened: false,
            docsWidth: null,
        };

        this.toggleDropdownVisibility = this.toggleDropdownVisibility.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.handleResizeWindow = this.handleResizeWindow.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizeWindow);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizeWindow);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isOpened && prevProps.isOpened) {
            this.setState({
                isDropdownOpened: false,
                docsWidth: null,
            });
            this.props.resetFilter();
        }

        if (!prevProps.hasDocs && this.props.hasDocs) {
            const docsWidth = document.querySelector('.results-section--documents').clientWidth;
            this.setState({
                docsWidth,
            });
        }
        if (!prevState.isDropdownOpened && this.state.isDropdownOpened) {
            document.querySelector('.search-popup__container').addEventListener('click', this.closeDropdown);
        }
        if (prevState.isDropdownOpened && !this.state.isDropdownOpened) {
            document.querySelector('.search-popup__container').removeEventListener('click', this.closeDropdown);
        }

        if (prevProps.results !== this.props.results) {
            setTimeout(() => {
                const documents = document.querySelector('.results-section--documents');
                if (documents) {
                    documents.style.height = `${documents.clientHeight}px`;
                    if (this.state.docsWidth) {
                        documents.style.width = `${this.state.docsWidth}px`; // to fix width of flexible child when content is being changed
                    }
                }
                const places = document.querySelector('.results-section--places');
                if (places) {
                    places.style.height = `${places.clientHeight}px`;
                }
                const food = document.querySelector('.results-section--food');
                if (food) {
                    food.style.height = `${food.clientHeight}px`;
                }
            }, 0);
        }
    }

    handleResizeWindow() {
        if (this.resizeTimer) {
            clearTimeout(this.resizeTimer);
        }
        setTimeout(() => {
            const places = document.querySelector('.results-section--places');
            const food = document.querySelector('.results-section--food');
            const docs = document.querySelector('.results-section--documents');

            if (places) {
                places.style.height = 'auto'; // setting "auto" here and below to get the real value without inline styles
                // places.style.height = places.clientHeight + 'px';
            }

            if (docs) {
                docs.style.height = 'auto';
                // docs.style.height = docs.clientHeight + "px";
                docs.style.width = 'auto';
                const docsWidth = docs.clientWidth;
                this.setState({
                    docsWidth,
                });
            }

            if (food) {
                food.style.height = 'auto';
                // food.style.height = food.clientHeight + 'px';
            }
        }, 200);
    }

    closeDropdown(e, flag = false) {
        if (e && (e.target.classList.contains('results-section__dropdown') || e.target.classList.contains('results-section__dropdown-item')) && !flag) {
            return;
        }
        this.setState({
            isDropdownOpened: false,
        });
    }

    renderCategories(categories) {
        const { openedCategory, documentsFilterArray, } = this.props;
        const selectedFilter = documentsFilterArray.find(filter => filter.isSelected);
        return categories.map((category, index) => {
            const additionalClass = '';
            return (
                <ResultsCategory
                    key={`category-${index}`}
                    {...category}
                    className={additionalClass}
                    isOpened={openedCategory === category.name}
                    selectedFilter={{
                        id: selectedFilter.id,
                        months: selectedFilter.months,
                    }}
                />
            );
        });
    }

    toggleDropdownVisibility() {
        this.setState({
            isDropdownOpened: !this.state.isDropdownOpened,
        });
    }

    setDocumentsFilter(id, e) {
        e.stopPropagation();
        e.preventDefault();
        // this.props.resetFilters();
        const newFilters = [...this.props.documentsFilterArray].map((filter) => {
            return {
                ...filter,
                isSelected: id + 1 === filter.id
            };
        });
        this.props.setDocumentFilters(newFilters);
        this.closeDropdown(e, true);
    }

    renderSection(name) {
        const { isSpinnerShown, chainsWhoOffersCount, query, } = this.props;
        const results = this.props.results
            .filter(category => category.section === name).concat()
            .sort((a, b) => a.sectionOrderNumber - b.sectionOrderNumber - 0.5);
        const dropdownClassList = new Set(['results-section__dropdown']);

        if (this.state.isDropdownOpened) {
            dropdownClassList.add('results-section__dropdown--opened');
        }

        switch (name) {
            case 'documents':
                return (
                    <div>
                        {!isSpinnerShown ? (
                            <div className={`results-section results-section--${name}`}>
                                <div
                                    className={`results-section__title results-section--${name}__title`}
                                    data-testid={`labelSearchPopupGroupTitle${capitalize(name, true)}`}
                                >
                                    {capitalize(name)}
                                    <div
                                        className={Array.from(dropdownClassList).join(' ')}
                                        onClick={this.toggleDropdownVisibility}
                                        data-testId="selectSearchPopupDocumentsTimeframe"
                                    >
                                        {this.props.documentsFilterArray.find(filter => filter.isSelected).title}
                                        <div
                                            className="results-section__dropdown-items-list"
                                            data-testId="selectSearchPopupDocumentsTimeframeOptions"
                                        >
                                            {this.props.documentsFilterArray.map((filter, index) => {
                                                const classList = new Set(['results-section__dropdown-item']);
                                                if (filter.isSelected) {
                                                    classList.add('results-section__dropdown-item--selected');
                                                }

                                                return (
                                                    <div
                                                        key={index}
                                                        onClick={this.setDocumentsFilter.bind(this, index)}
                                                        className={Array.from(classList).join(' ')}
                                                        data-testId={`selectSearchPopupDocumentsTimeframeOption${index}`}
                                                    >
                                                        {filter.title}
                                                    </div>
                                                );
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                                {results.length > 0
                                    ? this.renderCategories(results)
                                    : 'There are no results that satisfies your filter'
                                }
                                <Icon
                                    iconId={`auto_suggest_${name}`}
                                    className={`results-section__icon results-section__icon--${name}`}
                                />
                            </div>
                        ) : (
                            <div className={`results-section results-section--${name}`}>
                                <div className="results-section__spinner-wrap">
                                    <div className="spinner spinner-blink">
                                        <Icon iconId="logo-default"/>
                                    </div>
                                </div>
                                <Icon
                                    iconId={`auto_suggest_${name}`}
                                    className={`results-section__icon results-section__icon--${name}`}
                                />
                            </div>
                        )}
                    </div>
                );
            default: {
                const subTitleClassList = new Set([
                    'results-section__sub-title',
                    `results-section--${name}__sub-title`,
                ]);
                let subTitleText = '';
                let subTitleLink = '';
                if (name === 'places') {
                    subTitleText = `on the menu at ${chainsWhoOffersCount} top chains`;
                    subTitleLink = `/places?q=${encodeURIComponent(query)}&tabIndex=2`;
                }
                return (
                    <div className={`results-section results-section--${name}`}>
                        <div
                            className={`results-section__title results-section--${name}__title`}
                            data-testid={`labelSearchPopupGroupTitle${capitalize(name, true)}`}
                        >
                            {capitalize(name)}
                        </div>
                        {results.length > 0
                            ? this.renderCategories(results)
                            : 'There are no results that satisfies your filter'
                        }
                        <Icon
                            iconId={`auto_suggest_${name}`}
                            className={`results-section__icon results-section__icon--${name}`}
                        />
                        {name === 'places' && chainsWhoOffersCount > 0 ? (
                            <Link
                                to={subTitleLink}
                                className={Array.from(subTitleClassList).join(' ')}
                                data-testid={'buttonSearchPopupGroupAlternativeResults'}
                            >
                                {subTitleText}
                            </Link>
                        ) : null}
                    </div>
                );
            }
        }
    }

    renderOpenedCategoryResults(items) {
        const { query } = this.props;
        return <ColumnsList query={query} items={items}/>;
    }

    renderOpenedCategory() {
        const { openedCategoryData } = this.props;
        const { title, items, type } = openedCategoryData;
        const classList = [
            'search-popup-category',
            'search-popup-category--expanded',
            `search-popup-category--${type}`,
            `search-popup-category-expanded--${type}`
        ];
        return (
            <div className={classList.join(' ')}>
                <div className="search-popup-category__title search-popup-category--expanded__title">
                    {title}
                </div>
                <Scrollbars>
                    {this.renderOpenedCategoryResults(items)}
                </Scrollbars>
            </div>
        );
    }

    render() {
        const {
            openedCategory,
            hasFoods,
            hasChains,
            hasDocs,
        } = this.props;
        const classList = new Set(['search-popup__results-wrapper']);

        if (openedCategory !== '') {
            classList.add('search-popup__results-wrapper--expand-category');
        }

        return (
            <div className={Array.from(classList).join(' ')}>
                <Scrollbars autoHide>
                    <div className="search-popup__results-container">
                        {hasFoods && (
                            <div className="search-popup__section-column">
                                {hasFoods && this.renderSection('food')}
                            </div>
                        )}
                        {(hasDocs || hasChains) && (
                            <div className={`
                                search-popup__section-column
                                search-popup__section-column--2x
                                ${hasFoods ? '' : 'search-popup__section-column--full'}
                            `}>
                                {hasChains && this.renderSection('places')}
                                {hasDocs && this.renderSection('documents')}
                            </div>
                        )}
                    </div>
                </Scrollbars>
                {openedCategory && this.renderOpenedCategory()}
            </div>
        );
    }
}

Results.propTypes = {
    results: PropTypes.array,
    openedCategory: PropTypes.string,
    openedCategoryData: PropTypes.object,
    show: PropTypes.bool,
    hasFoods: PropTypes.bool,
    hasChains: PropTypes.bool,
    chainsWhoOffersCount: PropTypes.number,
    hasDocs: PropTypes.bool,
    query: PropTypes.string,
    data: PropTypes.object,
    documentsFilterArray: PropTypes.array,
    isOpened: PropTypes.bool,
    resetFilter: PropTypes.func,
    setDocumentFilters: PropTypes.func,
    isSpinnerShown: PropTypes.bool,
};

const mapStateToProps = state => ({
    hasFoods: hasFoodsSelector(state),
    hasChains: hasChainsSelector(state),
    hasDocs: hasDocsSelector(state),
    query: state.searchPopup.query,
    chainsWhoOffersCount: chainsWhoOffersCountSelector(state),
    isSpinnerShown: state.searchPopup.isSpinnerShown,
});

export default connect(mapStateToProps)(Results);
