import { FC, useEffect } from "react";
import Table from "../../components/new/Table/Table";
import { iTableConfigItem } from "../../components/new/Table/Table.helpers";
import useTableSort from "../../components/new/Table/useTableSort";
import { useTableConfig } from "./WhoOffers.helpers";
import './WhoOffers.styles.scss';
import { CustomBaseScrollbars } from "../../components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import { API__ChainMenuAndLtosViewModel } from "../../core/apiSchema";
import CopyButton from "../../components/new/Table/CopyButton";
import { sanitizeHtml } from "../../core/helpers";
import TableHead from "../../components/new/Table/TableHead";
import CopyNotification from "../../components/new/Table/CopyNotification";
import useNotification from "../../core/hooks/useNotification";
import { TableContext } from "../../components/new/Table/TableContext";


interface iProps {
    setCount: (count: number) => void;
    data: API__ChainMenuAndLtosViewModel[];
}

const WhoOffers: FC<iProps> = (
    {
        setCount,
        data,
    }
) => {
    const {
        handleSort,
        sortingField,
        sortingDirection,
        getColumnIcon,
        sortedData,
    } = useTableSort(data, "chainName");
    const notification = useNotification();
    const tableConfig: iTableConfigItem[] = useTableConfig(
        sortedData,
        sortingField,
        sortingDirection,
        handleSort,
        getColumnIcon,
    );

    useEffect(() => {
        setCount(data.length);
    }, [data.length]);

    return (
        <div className="NewTopChains__table">
            <div
                className="NewTopChains__table-copy"
                onClick={notification.handleShowNotification}
            >
                <CopyButton data={[
                        tableConfig.map(i => i.title || ''),
                        ...sortedData.map(chain => {
                            const items = chain.menuItems[0];
                            return [
                                chain.chainName,
                                `${sanitizeHtml(items.title)} - ${sanitizeHtml(items.description)}`,
                                chain.menuItems.length > 1 ? `plus ${chain.menuItems.length - 1} more` : ``
                            ]
                        })
                    ]}
                />
            </div>

            <TableContext.Provider value={{ config: tableConfig }}>
                <TableHead styles={{ paddingRight: 30 }}/>
            </TableContext.Provider>

            <div className="NewTopChains__table-container">
                <CustomBaseScrollbars>
                    <Table
                        rowHeight={'auto'}
                        hasInnerScroll={false}
                        hasHeader={false}
                        config={tableConfig}
                        rowsCount={data.length}
                    />
                </CustomBaseScrollbars>

                <CopyNotification isOpen={notification.isOpen} />
            </div>
        </div>
    );
};

export default WhoOffers;