import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import HomePage from './Homepage/Homepage';
import GroupsPage from './Groups/Groups';
import ReconfirmationPopup from '../containers/ReconfirmationPopup/ReconfirmationPopup';
import MyDocumentsPage from './MyDocuments/MyDocuments';
import ProjectPage from './Project/Project';
import DocumentTypePage from './DocumentType/DocumentType';
import ErrorPage from './Error/404';
import PageHeader from '../containers/PageHeader/PageHeader';
import DocumentsLibraryPopups from '../containers/DocumentsLibraryPopups/DocumentsLibraryPopups';
import AllMacs from './Documents/AllPages/AllMacs';
import InstantCharts from './InstantCharts/InstantCharts';
import PackagesPage from './PackagesPage/PackagesPage';
import GlobalNotification from '../components/GlobalNotification/GlobalNotification';

import getQueryParam from '../core/getQueryParam';
import SearchPopup from '../containers/SearchPopup/SearchPopup';
import ChangePassNotification from '../components/ChangePassNotification/ChangePassNotification';
import ImpersonatePopup from '../containers/ImpersonatePopup/ImpersonatePopup';
import ChainPage from "@pages/ChainPage/ChainPage";
import { routeNames } from "@core/routeNames";
import FireflyChains from "./NewTopChains/FireflyChains";
import Places from "./NewTopChains/Places";
import { AppContext } from "../AppContext";
import Documents from './NewDocuments/Documents/Documents';
import DocumentView from './NewDocuments/DocumentView/DocumentView';
import FoodProfile from "@pages/FoodProfile/FoodProfile";
import TestPage from './TestPage';
import { GtmInit, PlatformNavigation, usePlatformFeatureFlag } from "@datassential/platform-ui-lib";
import { FC } from "react";
import SearchTabs from "@pages/SearchTabs/SearchTabs";
import PackagesModals from "@containers/PageHeader/PackagesModals";

const AppPage = (options) => {
    return (props) => (
        <>
            <DocumentTitle title={options.pageTitle || 'SNAP by Datassential'}/>
            <options.component {...props} />
        </>
    );
};

const HeaderComponent = ({ featureFlags }) => {

    return (
        <>
            <PlatformNavigation/>
        </>
    );
}

const agencyModeCompanies = window.pageData.agencyModeCompanies || [];

function RouterComponent() {
    const {
        globalVariables: { hasFFChainsEnableLoginPermission, featureFlags }
    } = useContext(AppContext);

    const isFFChainsAllowed = hasFFChainsEnableLoginPermission;

    return (
        <Router basename="/">
            <Route render={({ location }) =>
                <div>
                    <HeaderComponent featureFlags={featureFlags}/>
                    {process.env.NODE_ENV !== 'development' && <GtmInit/>}
                    <ReconfirmationPopup/>
                    <DocumentsLibraryPopups/>
                    <ChangePassNotification/>
                    <ImpersonatePopup/>
                    <PackagesModals/>
                    <GlobalNotification/>
                    <Switch>
                        <Route exact path="/test" component={TestPage}/>
                        <Route
                            exact path={routeNames.home}
                            component={AppPage({
                                component: HomePage,
                                animationClass: 'route-change-slide'
                            })}
                        />
                        <Route
                            exact path={routeNames.search}
                            component={AppPage({
                                component: SearchTabs,
                            })}
                        />
                        <Route
                            path={routeNames.food}
                            component={AppPage({
                                component: FoodProfile,
                                animationClass: 'route-change',
                                pageTitle: `
                                        SNAP by Datassential | Results for ${getQueryParam(location.search, 'q')}
                                    `
                            })}
                        />
                        <Route
                            path={routeNames.places}
                            component={AppPage({
                                component: Places,
                                animationClass: 'route-change',
                                pageTitle: `
                                        SNAP by Datassential | Results for ${getQueryParam(location.search, 'q')}
                                    `
                            })}
                        />
                        <Route
                            path={routeNames.documents}
                            component={AppPage({
                                component: Documents,
                                animationClass: 'route-change',
                                pageTitle: `
                                        SNAP by Datassential | Results for ${getQueryParam(location.search, 'q')}
                                    `
                            })}
                        />
                        <Route
                            path={routeNames.myDocuments}
                            component={AppPage({
                                component: MyDocumentsPage,
                                animationClass: 'route-change',
                                pageTitle: `
                                        SNAP by Datassential | Documents library
                                `
                            })}
                        />
                        <Route
                            path={routeNames.projects}
                            component={AppPage({
                                component: ProjectPage,
                                animationClass: 'route-change',
                                pageTitle: `
                                        SNAP by Datassential | Results for ${getQueryParam(location.search, 'q')}
                                    `
                            })}
                        />
                        <Route
                            path={routeNames.documentType}
                            component={AppPage({
                                component: DocumentTypePage,
                                animationClass: 'route-change',
                                pageTitle: `
                                        SNAP by Datassential | Results for ${getQueryParam(location.search, 'q')}
                                    `
                            })}
                        />
                        <Route
                            path={routeNames.viewDocument}
                            component={AppPage({
                                component: DocumentView,
                                animationClass: 'route-change'
                            })}
                        />
                        {isFFChainsAllowed && (
                            <Route
                                path={routeNames.fireflyChains}
                                component={AppPage({
                                    component: FireflyChains,
                                    animationClass: 'route-change'
                                })}
                            />
                        )}
                        <Route
                            path={routeNames.chainDetails}
                            component={AppPage({
                                component: ChainPage,
                                animationClass: 'route-change'
                            })}
                        />
                        <Route
                            path={routeNames.allMacs}
                            component={AppPage({
                                component: AllMacs,
                                animationClass: 'route-change'
                            })}
                        />
                        <Route
                            path={routeNames.instantCharts}
                            component={AppPage({
                                component: InstantCharts,
                                animationClass: 'route-change'
                            })}
                        />
                        <Route
                            path={routeNames.packages}
                            component={AppPage({
                                component: PackagesPage,
                                animationClass: 'route-change'
                            })}
                        />
                        <Route
                            path={routeNames.groups}
                            component={AppPage({
                                component: GroupsPage,
                                animationClass: 'route-change'
                            })}
                        />
                        <Route
                            path={routeNames.error}
                            component={ErrorPage}
                        />
                        <Redirect to="/"/>
                    </Switch>
                </div>
            }/>
        </Router>
    );
}

export default RouterComponent;
