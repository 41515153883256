import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Icon from '../../components/SpriteIcon/SpriteIcon';
import http from '../../core/fetch';
import analytics from '../../core/analytics';
import {
    changeSearchPopupQuery,
    closeOpenedSearchPopupCategory,
    clearSearchPopupResults,
    updateSearchPopupQuery,
    showDocumentsAnyway,
    dontShowDocumentsAnyway,
    changeSearchFilter,
} from '../../actions/searchPopup.actions';
import { closeSearchPopup, setAvailableSearchTabs } from '../../actions/page';
import {
    searchPopupResultsSelector,
    searchPopupAutocompleteSelector,
    openedCategorySelector,
    openedCategoryResultsSelector,
    hasResultsSelector
} from '../../selectors/searchPopup.selectors';
import serializeParams from '../../core/serializeParams';
import Results from './Results';

import './SearchPopup.scss';

// const LETTERS_KEYS_LIST = new Set([
//     81, 87, 69, 82, 84, 89, 85, 73, 79, 80, 76, 75, 74, 72, 71, 70, 68, 91, 92, 93, 94, 96, 97, 98, 99, 100,
//     101, 102, 103, 104, 105, 83, 65, 90, 88, 67, 86, 66, 78, 77, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48
// ]);

class SearchPopup extends Component {
    constructor(props) {
        super(props);
        this.onInput = this.onInput.bind(this);
        this.onInputKeyDown = this.onInputKeyDown.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.inputRef = createRef();
        this.state = {
            showResults: true,
            showResultsRequestSent: false,
            documentsFilterArray: [
                { id: 1, title: '1 month', isSelected: false, months: 1, },
                { id: 2, title: '3 months', isSelected: false, months: 3, },
                { id: 3, title: '6 months', isSelected: false, months: 6, },
                { id: 4, title: '12 months', isSelected: false, months: 12, },
                { id: 5, title: 'all time', isSelected: true, months: null },
            ],
            selectedFilter: 5,
        };
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isOpened && prevProps.isOpened) {
            this.setState({ showResultsRequestSent: false });
            setTimeout(() => {
                this.inputRef.current.blur();
            }, 100);
        }
        if (this.props.isOpened && !prevProps.isOpened) {
            setTimeout(() => {
                this.inputRef.current.focus();
            }, 500);
        }
    }

    // /a-zA-Z\d\s \-\'\&\(\)\.\#\,\/\:/
    onInputKeyDown(event) {
        const searchInputValue = event.target.value;
        const keyCode = event.which;
        const { changeSearchQuery, autocomplete } = this.props;
        const isCaretInTheEnd = searchInputValue.length === event.target.selectionStart;

        if (keyCode === 8) { // backspace
            this.setState({ showResults: false });
            clearTimeout(this.backspaceTimer);
            this.backspaceTimer = setTimeout(() => {
                this.setState({ showResults: true });
            }, 500);
        }

        const isValue = searchInputValue !== '';
        const isAutoComplete = autocomplete !== '';
        const isAutocompleted = searchInputValue === autocomplete;

        if (keyCode === 39 && isValue && isAutoComplete && isCaretInTheEnd && !isAutocompleted) {
            changeSearchQuery(autocomplete);
        }
    }

    onInput(event) {
        const input = event.target;
        const inputValue = input.value;
        // const selectionStart = input.selectionStart;
        const { changeSearchQuery } = this.props;
        const newVal = inputValue.replace(/^\s+|[^0-9A-zА-я#&():/\-\\,.'@+’\s]/g, '');
        // this.props.dontShowDocumentsAnyway();
        this.resetFilters();
        changeSearchQuery(newVal.toLowerCase());
        // setTimeout(() => {
        //     input.selectionStart = (selectionStart + newVal.length) - inputValue.length;
        //     input.selectionEnd = (selectionStart + newVal.length) - inputValue.length;
        // }, 50);
    }

    redirectToResults(data) {
        const { results, history } = this.props;
        let redirectLink;
        let params = serializeParams({ q: this.props.query.trim() });

        if (data.foodProfilesExists) {
            redirectLink = 'food';
        } else if (data.placesExists) {
            redirectLink = 'places';
        } else if (data.documentsExists) {
            const isFirstResultDocs = results[0].type === 'myDocs';
            const isAllResultsProjects = results[0].items.filter(({ type }) => type !== 'project').length === 0;

            if (results.length === 1 && isFirstResultDocs && isAllResultsProjects) {
                redirectLink = 'project';
                params = `id=${results[0].items[0].instanceGuid}`;
            } else {
                redirectLink = 'documents';
            }
        } else {
            redirectLink = 'search';
        }

        history.push(`/${redirectLink}?${params}`);
    }

    handleSubmit(e) {
        e.preventDefault();
        const { results, isLoading, hasResults, setAvailableSearchTabs, history } = this.props;
        const { showResultsRequestSent } = this.state;
        const foodResults = results.filter(result => result.section === 'food');
        const placesResults = results.find(result => result.type === 'chain');
        const foodResultsCount = foodResults.reduce((total, result) => total + result.items.length, 0);

        if (!isLoading && !showResultsRequestSent && hasResults) {
            analytics.sendSearch(this.props.query);
            if (foodResultsCount === 0 && placesResults && placesResults.items.length === 1) {
                history.push(`/chainDetails?id=${placesResults.items[0].id}`);
            } else {
                this.setState({ showResultsRequestSent: true });
                http.get('active tabs', { word: this.props.query })
                    .then((data) => {
                        setAvailableSearchTabs(data);
                        this.redirectToResults(data);
                    });
            }
        }
    }

    renderMainInput() {
        const { query } = this.props;
        return (
            <input
                ref={this.inputRef}
                id="search_popup_input"
                type="text"
                className="search-popup__input"
                onInput={this.onInput}
                onKeyDown={this.onInputKeyDown}
                autoComplete="off"
                value={query}
                data-testid="inputSearchPopupQuery"
            />
        );
    }

    renderAutoCompleteInput() {
        const { autocomplete } = this.props;
        return (
            <input
                type="text"
                className="search-popup__input-autocomplete"
                value={autocomplete}
                readOnly
            />
        );
    }

    resetFilters() {
        this.setDocumentFilters(this.state.documentsFilterArray.map((filter, id) => {
            if(id + 1 === 5) {
                filter.isSelected = true;
            } else {
                filter.isSelected = false;
            }
            return filter;
        }));
        clearTimeout(this.timeout);
        this.props.dontShowDocumentsAnyway();
    }

    setDocumentFilters(filters) {
        this.setState({
            documentsFilterArray: [...filters],
            selectedFilter: filters.find(filter => filter.isSelected).id,
        });

        this.timeout = setTimeout(() => {
            if(this.state.selectedFilter !== 5) {
                const date = new Date();
                switch(this.state.selectedFilter) {
                    case 1:
                        date.setMonth(date.getMonth() - 1);
                        break;
                    case 2:
                        date.setMonth(date.getMonth() - 3);
                        break;
                    case 3:
                        date.setMonth(date.getMonth() - 6);
                        break;
                    case 4:
                        date.setMonth(date.getMonth() - 12);
                        break;
                    default:
                        break;
                }
                const stringDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                this.props.showDocumentsAnyway();
                this.props.changeSearchFilter(this.props.query, stringDate);
            } else {
                this.props.changeSearchFilter(this.props.query);
            }
        }, 0);
    }

    render() {
        const {
            query, results, isOpened, close, closeOpenedCategory, openedCategory, openedCategoryData,
            isLoading, hasResults
        } = this.props;

        let classList = ['search-popup__container'];

        if (isOpened) {
            classList.push('search-popup__container--opened');
        }
        classList = classList.join(' ');
        return (
            <div className={classList}>
                <button
                    onClick={close}
                    className="search-popup__close clickable"
                    data-testid="buttonSearchPopupClose"
                >
                    close
                </button>
                <div
                    className="search-popup__title"
                    data-testid="labelSearchPopupInput"
                >
                    Search for foods, places, articles and more
                </div>
                <div className="search-popup__input-container">
                    <form onSubmit={this.handleSubmit} className="search-popup__form">
                        {this.renderMainInput()}
                        {this.renderAutoCompleteInput() }
                        { query !== '' && openedCategory === '' && hasResults && (
                            <button
                                type="submit"
                                className="search-popup__go-input"
                                id="search-popup-submit"
                                data-testid="buttonSearchPopupSubmit"
                            >
                                <Icon iconId={'auto_suggest_go'} height={60} width={100}/>
                            </button>
                        ) }
                        { openedCategory !== '' && (
                            <button className="search-popup__back" onClick={closeOpenedCategory}>« go back</button>
                        ) }
                    </form>
                </div>
                {
                    query !== '' ? (
                        <div
                            className="search-popup__title search-popup__title--right"
                            data-testid="labelSearchPopupInputBelow"
                        >
                            or select from below
                        </div>
                    ) : null
                }
                <Results
                    openedCategory={openedCategory}
                    openedCategoryData={openedCategoryData}
                    results={results}
                    query={query}
                    show={this.state.showResults}
                    isOpened={this.props.isOpened}
                    documentsFilterArray={this.state.documentsFilterArray}
                    setDocumentFilters={this.setDocumentFilters.bind(this)}
                    resetFilter={this.resetFilters}
                />
                {!isLoading && !hasResults && query !== '' && (
                    <div className="search-popup__message">
                        Hmm... SNAP covers thousands of topics, but we don’t seem to have anything on
                        “{query}”.
                        Please try searching for something else.
                    </div>
                )}
                {this.state.showResultsRequestSent && (
                    <div className="search-popup__overlay">
                        <div className="spinner spinner-blink">
                            <Icon iconId="logo-collapsed"/>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

SearchPopup.propTypes = {
    query: PropTypes.string,
    autocomplete: PropTypes.string,
    results: PropTypes.array,
    changeSearchQuery: PropTypes.func,
    isOpened: PropTypes.bool,
    close: PropTypes.func,
    goToResults: PropTypes.func,
    hasBeenMount: PropTypes.bool,
    changeRoute: PropTypes.func,
    openedCategory: PropTypes.string,
    openedCategoryData: PropTypes.object,
    closeOpenedCategory: PropTypes.func,
    clearSearchPopupResults: PropTypes.func,
    setAvailableSearchTabs: PropTypes.func,
    hasResults: PropTypes.bool,
    isLoading: PropTypes.bool,
    updateSearchPopupQuery: PropTypes.func,
    history: PropTypes.object
};

const mapStateToProps = state => ({
    query: state.searchPopup.query,
    autocomplete: searchPopupAutocompleteSelector(state),
    results: searchPopupResultsSelector(state),
    openedCategory: openedCategorySelector(state),
    isOpened: state.pageStatus.isSearchPopupOpened,
    openedCategoryData: openedCategoryResultsSelector(state),
    hasResults: hasResultsSelector(state),
    isLoading: state.searchPopup.isLoading
});

const mapDispatchToProps = dispatch => ({
    changeSearchQuery: (searchQuery) => dispatch(changeSearchPopupQuery(searchQuery)),
    updateSearchPopupQuery: searchQuery => dispatch(updateSearchPopupQuery(searchQuery)),
    close: () => dispatch(closeSearchPopup()),
    closeOpenedCategory: categoryName => dispatch(closeOpenedSearchPopupCategory(categoryName)),
    clearSearchPopupResults: () => dispatch(clearSearchPopupResults()),
    setAvailableSearchTabs: data => dispatch(setAvailableSearchTabs(data)),
    showDocumentsAnyway: () => dispatch(showDocumentsAnyway()),
    dontShowDocumentsAnyway: () => dispatch(dontShowDocumentsAnyway()),
    changeSearchFilter: (query, date) => dispatch(changeSearchFilter(query, date)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchPopup));
