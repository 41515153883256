import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter, NavLink } from 'react-router-dom';
import analytics from '@core/analytics';
import {
    headerNavigationItemsSelector,
    headerHomeUrlSelector,
    availableSearchTabsSelector,
} from '@selectors/page';
import { openSearchPopup, setAvailableSearchTabs } from '@actions/page';
import SpriteIcon from '@components/SpriteIcon/SpriteIcon';
import HeaderUserActionsMenu from './HeaderUserActionsMenu/HeaderUserActionsMenu';
import {
    HEADER__OPEN_FOOD_SCAPE_POPUP, HEADER__CLOSE_FOOD_SCAPE_POPUP, OPEN_AGENCY_MODE_POPUP
} from '@actions/actionsNamesList';
import './PageHeader.scss';
import { routeNames } from "@core/routeNames";
import PlatformNavigation from "@containers/PageHeader/PlatformNavigation/PlatformNavigation";
import cn from "classnames";

class PageHeader extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isDashboardRoute: false, isVisible: true, isSidebarOpened: false, animateTabletLogo: false
        };
        this.switchSidebar = this.switchSidebar.bind(this);
    }

    componentDidMount() {
        if (this.props.location.pathname === '/') {
            this.setState({ isDashboardRoute: true });
            this.tabletAnimationTimer = setTimeout(() => {
                this.setState({ animateTabletLogo: true });
            }, 5000);
        }
        if (this.props.location.pathname === '/404') {
            this.setState({ isVisible: false });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                isDashboardRoute: this.props.location.pathname === '/',
                isVisible: this.props.location.pathname !== '/404'
            });
        }

    }

    switchSidebar(e) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            isSidebarOpened: !this.state.isSidebarOpened
        });
        clearTimeout(this.tabletAnimationTimer);
        this.setState({ animateTabletLogo: true });
    }

    renderSearchResultsPageHeader() {
        const { availableSearchTabs, location } = this.props;
        const searchString = new window.URLSearchParams(this.props.location.search);
        const query = searchString.get('q');
        const isSearchResultsRoute = location.pathname === routeNames.food || location.pathname === routeNames.places || location.pathname === routeNames.documents || (query !== null && !!query.trim() && location.pathname !== routeNames.myDocuments);

        return (
            <div className="search-results__header">
                <button className="search-results__search-button search-button"
                        onClick={this.props.openSearchPopup}>
                    <span className="search-button__icon">
                        <SpriteIcon iconId="search-small" width="22" height="22"/>
                    </span>
                </button>
                {isSearchResultsRoute && <div className="search-results__tabs">
                    {availableSearchTabs.foodProfilesExists && <NavLink
                        onClick={() => analytics.sendThreeTabs('Food')}
                        to={{
                            pathname: '/food',
                            search: `?q=${query ? encodeURIComponent(query) : encodeURIComponent(this.props.searchQuery)}`
                        }}
                        activeClassName="rounded-button__active"
                        className="search-results-tabs__thumbnail rounded-button rounded-button--center-aligned"
                    >
                        food
                    </NavLink>}
                    {availableSearchTabs.placesExists && <NavLink
                        onClick={() => analytics.sendThreeTabs('Places')}
                        to={{
                            pathname: '/places',
                            search: `?q=${query ? encodeURIComponent(query) : encodeURIComponent(this.props.searchQuery)}`
                        }}
                        activeClassName="rounded-button__active"
                        className={`search-results-tabs__thumbnail rounded-button ${query !== null && query.trim() && location.pathname === '/chainDetails' ? 'rounded-button__active-immitation' : ''} rounded-button--center-aligned`}
                    >
                        places
                    </NavLink>}
                    {availableSearchTabs.documentsExists && <NavLink
                        onClick={() => analytics.sendThreeTabs('Documents')}
                        to={{
                            pathname: '/documents',
                            search: `?q=${query ? encodeURIComponent(query) : encodeURIComponent(this.props.searchQuery)}`
                        }}
                        activeClassName="rounded-button__active"
                        className={`search-results-tabs__thumbnail rounded-button rounded-button--center-aligned`}
                    >
                        docs
                    </NavLink>}
                </div>}
            </div>
        );
    }

    render() {
        const {
            isSearchPopupOpened, isBlurred, isFoodScapePopupOpened
        } = this.props;
        const classList = cn(
            'PageHeader',
            {
                'PageHeader--search-popup-opened': !this.state.isDashboardRoute && isSearchPopupOpened,
                'PageHeader--blurred': isBlurred || (isFoodScapePopupOpened && this.state.isDashboardRoute)
            }
        );

        return (
            <section>
                {this.state.isVisible ? <header className={classList}>
                    <div className="PageHeader__left">
                        {this.state.isDashboardRoute ? (
                            <div className="PageHeader__logo">
                                <SpriteIcon
                                    iconId="logo-snap-ds"
                                    height="48"
                                    width="158"
                                    className="PageHeader__logo-text"
                                />
                                <div
                                    className={`PageHeader__logo-icon-wrapper ${this.state.isSidebarOpened ? 'clicked' : ''}`}
                                    onClick={this.switchSidebar}
                                >
                                    <SpriteIcon
                                        iconId="logo-snap-ds-icon"
                                        height="50"
                                        width="50"
                                        className={`PageHeader__logo-icon PageHeader__logo-icon--default ${this.state.animateTabletLogo ? 'PageHeader__logo-icon--default--hide' : ''}`}
                                    />
                                    <SpriteIcon
                                        iconId="logo-pin"
                                        height="50"
                                        width="50"
                                        className="PageHeader__logo-icon PageHeader__logo-icon--pin"
                                    />
                                    <SpriteIcon
                                        iconId="logo-hovered"
                                        height="50"
                                        width="50"
                                        className={`PageHeader__logo-icon PageHeader__logo-icon--hovered ${this.state.animateTabletLogo ? 'PageHeader__logo-icon--hovered--show' : ''}`}
                                    />
                                    <SpriteIcon
                                        iconId="logo-clicked"
                                        height="50"
                                        width="50"
                                        className="PageHeader__logo-icon PageHeader__logo-icon--clicked"
                                    />
                                </div>
                            </div>
                        ) : (
                            <Link to="/" className="PageHeader__logo">
                                <SpriteIcon
                                    iconId="logo-snap-ds"
                                    height="48"
                                    width="158"
                                    className="PageHeader__logo-text"
                                />
                                <div
                                    className={`PageHeader__logo-icon-wrapper ${this.state.isSidebarOpened ? 'clicked' : ''}`}
                                    onClick={this.switchSidebar}
                                >
                                    <SpriteIcon
                                        iconId="logo-snap-ds-icon"
                                        height="50"
                                        width="50"
                                        className="PageHeader__logo-icon PageHeader__logo-icon--default PageHeader__logo-icon--default--hide"
                                    />
                                    <SpriteIcon
                                        iconId="logo-pin"
                                        height="50"
                                        width="50"
                                        className="PageHeader__logo-icon PageHeader__logo-icon--pin"
                                    />
                                    <SpriteIcon
                                        iconId="logo-hovered"
                                        height="50"
                                        width="50"
                                        className="PageHeader__logo-icon PageHeader__logo-icon--hovered PageHeader__logo-icon--hovered--show"
                                    />
                                    <SpriteIcon
                                        iconId="logo-clicked"
                                        height="50"
                                        width="50"
                                        className="PageHeader__logo-icon PageHeader__logo-icon--clicked"
                                    />
                                </div>
                            </Link>
                        )}
                        {!this.state.isDashboardRoute && this.renderSearchResultsPageHeader()}
                        <PlatformNavigation
                            isOpened={this.state.isSidebarOpened}
                            isDashboardRoute={this.state.isDashboardRoute}
                        />
                    </div>
                    <div className="PageHeader__right">
                        <a
                            href={this.props.helpUrl}
                            target="_blank"
                            className="HelpCenterButton"
                        >
                            HELP CENTER
                        </a>
                        <HeaderUserActionsMenu/>
                    </div>
                </header> : null}
            </section>
        );
    }
}

PageHeader.propTypes = {
    homeUrl: PropTypes.string,
    userActionsMenuItems: PropTypes.array,
    documentsMenuItems: PropTypes.array,
    searchQuery: PropTypes.string,
    availableSearchTabs: PropTypes.object,
    location: PropTypes.object,
    openSearchPopup: PropTypes.func,
    isSearchPopupOpened: PropTypes.bool,
    isFoodScapePopupOpened: PropTypes.bool,
    isSnapAssistantReminderShown: PropTypes.bool,
    isBlurred: PropTypes.bool,
    closeFoodScapePopup: PropTypes.func,
    openFoodScapePopup: PropTypes.func,
    helpUrl: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
    homeUrl: headerHomeUrlSelector(state),
    searchQuery: state.pageStatus.searchString,
    isSnapAssistantReminderShown: state.pageStatus.isSnapAssistantReminderShown,
    isFoodScapePopupOpened: state.pageStatus.isFoodScapePopupOpened,
    searchResultsExist: state.pageStatus.searchResultsExist,
    availableSearchTabs: availableSearchTabsSelector(state),
    isSearchPopupOpened: state.pageStatus.isSearchPopupOpened,
    helpUrl: window.pageData.helpUrl,
});

const mapDispatchToProps = dispatch => ({
    openSearchPopup: () => dispatch(openSearchPopup()),
    setAvailableSearchTabs: data => dispatch(setAvailableSearchTabs(data)),
    openFoodScapePopup: () => dispatch({ type: HEADER__OPEN_FOOD_SCAPE_POPUP }),
    closeFoodScapePopup: () => dispatch({ type: HEADER__CLOSE_FOOD_SCAPE_POPUP }),
    openAgencyModePopup: () => dispatch({ type: OPEN_AGENCY_MODE_POPUP }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageHeader));
