import { useContext } from "react";
import NewTooltip from "../NewTooltip/NewTooltip";
import TableCell from "./TableCell";
import { TableContext } from "./TableContext";
import { iProps as iCellWrapperProps } from "./TableCellWrapper";

interface iProps {
    styles?: React.CSSProperties;
}

const TableHead: React.FC<iProps> = ({ styles = {}, children }) => {
    const { config } = useContext(TableContext);
    return (
        <div
            className="TableNew__head" style={styles}
            data-testid={"tableHead"}
        >
            {config.map((column, index) => {
                const props: Partial<iCellWrapperProps> = { head: true };

                if (column.handleHeaderClick) {
                    props.onClick = column.handleHeaderClick;
                }
                return (
                    <TableCell
                        key={index}
                        columnData={column}
                        isHoverable={!!column.tooltip || !!column.handleHeaderClick}
                        isActive={column.isActive}
                        props={props}
                        testId={`tableHeadCell${index}`}
                    >
                        {column.icon}
                        {column.renderTitle ? column.renderTitle : column.title}

                        {column.tooltip && (
                            <NewTooltip
                                className="TableNew__tooltip"
                            >
                                {column.tooltip}
                            </NewTooltip>
                        )}
                    </TableCell>
                );
            })}
            {children}
        </div>
    );
};

export default TableHead;
