import { FC, useCallback, useContext, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import analytics from '@core/analytics';
import DarkModal from '@components/LogoutPopup/DarkModal';
import { shouldRenderLogoutPopupSelector, userInitialsSelector } from "@selectors/page";
import { routeNames } from '@core/routeNames';
import useOutsideClick from '@hooks/useOutsideClick';
import './HeaderUserActionsMenu.scss';
import cn from 'classnames';
import { AppContext } from "../../../AppContext";
import { open as openPackagesPopup } from "@actions/packages.actions";

const HeaderUserActionsMenu: FC = () => {
    const {
        globalVariables: {
            hasAdminAccess,
            hasMacAdminAccess,
        },
    } = useContext(AppContext);

    const dispatch = useDispatch();
    const userInitials = useSelector(userInitialsSelector);
    const shouldRenderLogoutPopup = useSelector(shouldRenderLogoutPopupSelector);

    const ref = useRef<HTMLDivElement>(null);
    const [isOpened, setIsOpened] = useState(false);

    useOutsideClick({
        ref,
        fn: () => setIsOpened(false),
        isDisabled: false,
    });

    const [isLogoutPopupOpened, setIsLogoutPopupOpened] = useState(false);

    const logOut = useCallback(
        () => {
            analytics.sendLogout();
            window.location.href = `${process.env.REACT_APP_API}account/signOut`;
        },
        [],
    );

    return (
        <div
            ref={ref}
            className="HeaderUserActionsMenu"
            onClick={() => setIsOpened(!isOpened)}
        >
            <div
                className="HeaderUserActionsMenu__thumbnail"
                onMouseEnter={() => setIsOpened(true)}
            >
                {userInitials}
            </div>
            <div className={cn("HeaderUserActionsMenu__dropdown", isOpened && 'is-opened')}>
                <NavLink to={routeNames.myDocuments} className="HeaderUserActionsMenu__item">
                    My Docs
                </NavLink>
                {/* @ts-ignore */}
                <div
                    className="HeaderUserActionsMenu__item"
                    onClick={() => dispatch(openPackagesPopup())}
                >
                    Packages
                </div>
                <NavLink
                    to={routeNames.groups}
                    className="HeaderUserActionsMenu__item"
                    onClick={() => analytics.sendTeamsRedirect('teams')}
                >
                    Teams
                </NavLink>
                {hasMacAdminAccess && (
                    <a
                        target="_blank"
                        href={`${process.env.REACT_APP_API}Admin/Mac`}
                        className="HeaderUserActionsMenu__item"
                    >
                        MAC Admin
                    </a>
                )}
                {hasAdminAccess && (
                    <a
                        target="_blank"
                        href={`${process.env.REACT_APP_API}Admin/Company`}
                        className="HeaderUserActionsMenu__item"
                    >
                        SNAP Admin
                    </a>
                )}
                <div
                    className="HeaderUserActionsMenu__item"
                    onClick={() => {
                        if (shouldRenderLogoutPopup) {
                            setIsLogoutPopupOpened(true);
                        } else {
                            logOut();
                        }
                    }}
                >
                    Log Out
                </div>
            </div>
            <DarkModal
                onClose={() => setIsLogoutPopupOpened(false)}
                isOpened={isLogoutPopupOpened}
                textAlign="center"
            >
                <div className="DarkModal__title">
                    Are you sure? Logging out will also disconnect the SNAP Assistant. If you are not sharing
                    this computer, there’s no real need to log out.
                </div>
                <button
                    className="DarkModal__btn"
                    onClick={() => setIsLogoutPopupOpened(false)}
                    style={{ marginTop: 40 }}
                >
                    back to snap
                </button>
                <button className="DarkModal__link" onClick={logOut}>
                    log me out anyway
                </button>
            </DarkModal>
        </div>
    );

}

export default HeaderUserActionsMenu;
