import Http from '../fetch';
import { useData } from "../hooks/useData";
import { API__ChainMenuAndLtosViewModel, API__OfferingChain } from "../apiSchema";

async function getOfferingChains(q: string): Promise<API__ChainMenuAndLtosViewModel[]> {
    const data: API__OfferingChain  = await Http.get('offering chains', { SearchWord: q });

    return data.data;
}

export function useOfferingChains(q: string) {
    return useData([], getOfferingChains, q);
}