import { FC, useEffect, useState } from "react";
import './PlatformNavigation.style.scss';
import {
    INavigationItem,
    INavigationSection,
    useNavigationData
} from "@core/models/PlatformNavigation";
import { IconArrowRight, IconType } from "@icons";
import cn from "classnames";
import { Link } from "react-router-dom";
import { routeNames } from "@core/routeNames";
import analytics from "@core/analytics";


const NavigationItem: FC<{
    item: INavigationItem;
    section: INavigationSection;
    setActiveItem: (item: INavigationItem | null) => void;
    onClick: () => void;
}> = (
    {
        item,
        section,
        setActiveItem,
        onClick,
    }
) => {
    const Icon: IconType | undefined = item.isDisabled ? item.IconGs : item.Icon;

    const handleClick = () => {
        analytics.sendMainToolbar(item.dbName);
        onClick();
    };

    const children = (
        <>
            {section.hasIcons && (
                <div className="PlatformNavigation__item-icon">
                    {!!Icon && <Icon/>}
                </div>
            )}
            <div className="PlatformNavigation__item-title">
                <div className="PlatformNavigation__item-text">
                    {item.name}
                </div>
                {!!item.legacyName && (
                    <div className="PlatformNavigation__item-sub-text">
                        {item.legacyName}
                    </div>
                )}
            </div>
        </>
    );

    const props = {
        className: cn(
            "PlatformNavigation__item",
            "is-secondary",
            item.isDisabled && "is-disabled",
            !item.isDisabled && "clickable",
        ),
        onClick: item.isDisabled ? undefined : handleClick,
        onMouseEnter: () => setActiveItem(item),
        onMouseLeave: () => setActiveItem(null),
        children,
    };

    if (item.isDisabled) {
        return (
            <div {...props}/>
        );
    }
    else if (item.isInternal) {
        return (
            <Link
                {...props}
                to={item.url || routeNames.home }
            />
        )
    }
    else {
        return (
            <a
                {...props}
                href={item.url}
                target="_blank"
            />
        );
    }
};


const PlatformNavigation: FC<{
    className?: string;
    isOpened: boolean;
    isDashboardRoute: boolean;
}> = (
    {
        className,
        isOpened,
        isDashboardRoute,
    }
) => {
    const [isClicked, setIsClicked] = useState(false);
    const [isOpenedLocal, setIsOpenedLocal] = useState(false);
    const [activeItem, setActiveItem] = useState<INavigationItem | null>(null);
    const [mouseOffsetLeft, setMouseOffsetLeft] = useState(0);
    const data = useNavigationData();

    const handleActiveItemClick = () => {
        setIsClicked(true);
        setTimeout(() => {
            setIsClicked(false);
        }, 100);
    };

    useEffect(() => {
        setTimeout(() => {
            setIsOpenedLocal(true );
        }, 500);
        setTimeout(() => {
            setIsOpenedLocal(false );
        }, 4300);
    }, []);

    return (
        <>
            <div
                key={isOpened ? 'locked' : 'not-locked'}
                className={cn(
                    'PlatformNavigation',
                    !isDashboardRoute && 'is-compressed',
                    className,
                    isClicked && 'is-disabled',
                    (isOpenedLocal || isOpened) && 'is-opened',
                    isOpened && 'is-locked',
                )}
            >
                {data.map(section => (
                    <div
                        key={section.title}
                        className="PlatformNavigation__item"
                        onMouseMove={( event ) => {
                            if ((event.target as HTMLElement).classList.contains('PlatformNavigation__item'))
                            setMouseOffsetLeft(event.clientX);
                        }}
                        onMouseLeave={(  ) => {
                            setMouseOffsetLeft(0);
                        }}
                    >
                        {section.title}
                        <IconArrowRight width={18} height={18} style={{ marginLeft: 7, marginTop: -2 }}/>
                        <div className="PlatformNavigation__item-dropdown">
                            <div
                                className="PlatformNavigation__item-dropdown-triangle"
                                style={{ width: 192 - mouseOffsetLeft}}
                            />
                            {section.items.map(item => (
                                <NavigationItem
                                    key={item.name}
                                    item={item}
                                    section={section}
                                    setActiveItem={setActiveItem}
                                    onClick={handleActiveItemClick}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {activeItem && activeItem.hoverData && (() => {
                const { Icon } = activeItem;
                return (
                    <div className="PlatformNavigationPopup">
                        <div className="PlatformNavigationPopup__inner">
                            {!!Icon && (
                                <div className="PlatformNavigationPopup__side">
                                    <div className="PlatformNavigationPopup__icon">
                                        <Icon/>
                                    </div>
                                    <div className="PlatformNavigationPopup__icon-title">
                                        {activeItem.legacyName}
                                    </div>
                                </div>
                            )}
                            <div className="PlatformNavigationPopup__content">
                                <div className="PlatformNavigationPopup__header">
                                    <div className="PlatformNavigationPopup__title">
                                        {activeItem.name}
                                    </div>
                                    <div className="PlatformNavigationPopup__description">
                                        {activeItem.hoverData.description}
                                    </div>
                                    <div
                                        className="PlatformNavigationPopup__header-border"
                                        style={{ background: activeItem.hoverData.color}}
                                    />
                                </div>
                                <div className="PlatformNavigationPopup__bullets">
                                    {activeItem.hoverData.bullets.map(bullet => (
                                        <div
                                            className="PlatformNavigationPopup__bullet"
                                            dangerouslySetInnerHTML={{
                                                __html: bullet,
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })()}
        </>
    );
};

export default PlatformNavigation;